import { useEffect } from 'react';

const RSS_FEED_IDS = [
  'polAJNqtHFb7NA57',
  'iFY9b0OPoBmW7hbt', 
  'mkk0kMzACB4jvJfO',
  'RIQ3k2l7k8bUG8Rd',
  'nmT9Vwd1rYcucpSK',
  'GWzyraAYkkQqA2nf',
  'PabKA4pTOCrZvKu5',
  '6FlIKxlhbABJb0AA',
  'wdv35hxbF1qEt5tx',
  'cNnSefd443HSoYeS'
];

export function RssFeedList() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/feed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="space-y-8">
      {RSS_FEED_IDS.map((feedId) => (
        <div key={feedId} className="bg-white rounded-lg shadow-sm">
          <rssapp-feed id={feedId}></rssapp-feed>
        </div>
      ))}
    </div>
  );
}