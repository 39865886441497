import { useState } from 'react';
import { LogOut, Trash2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { auth } from '../lib/firebase';
import { Link } from 'react-router-dom';
import { MainMenu } from '../components/MainMenu';
import { DeleteAccountDialog } from '../components/DeleteAccountDialog';
import { RecentUsers } from '../components/RecentUsers';

export function Config() {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { user } = useAuth();

  const openSupportChat = () => {
    const width = Math.min(600, window.innerWidth * 0.9);
    const height = Math.min(800, window.innerHeight * 0.9);
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      'https://wproo.com/ia/atendimento/index.html',
      'Support Chat',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=no,location=no`
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
            title="Sign out"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-4">
        <div className="space-y-6">
          {/* Account Info Section */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Account Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={user?.displayName || ''}
                  disabled
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={user?.email || ''}
                  disabled
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-600"
                />
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <Link
              to="/what-is-poosting"
              className="block w-full px-6 py-3 bg-black text-white font-bold rounded-lg hover:bg-gray-900 transition-colors text-center mb-4"
            >
              What Is Poosting?
            </Link>

            <button
              onClick={openSupportChat}
              className="block w-full px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition-colors text-center mb-6"
            >
              Support Chat
            </button>

            <div className="mt-8 border-t border-gray-200 pt-6">
              <button
                onClick={() => setShowDeleteDialog(true)}
                className="w-full px-6 py-3 bg-red-500 text-white font-bold rounded-lg hover:bg-red-600 transition-colors text-center flex items-center justify-center space-x-2"
              >
                <Trash2 className="h-5 w-5" />
                <span>Delete My Account</span>
              </button>
              <p className="text-sm text-gray-500 text-center mt-2">
                Warning: This action is permanent and cannot be undone
              </p>
            </div>
          </div>

          {/* Recent Users Section */}
          <div className="border-t border-gray-200 pt-6">
            <RecentUsers />
          </div>

          {/* Footer Links */}
          <div className="border-t border-gray-200 pt-6 px-4">
            <div className="flex flex-col items-center space-y-2">
              <a
                href="https://poosting.com.br/doc/en/cookie-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-500"
              >
                Cookie Policy
              </a>
              <a
                href="https://poosting.com.br/doc/en/poosting-terms-of-use.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-500"
              >
                Terms of use
              </a>
              <a
                href="https://poosting.com.br/doc/en/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-500"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </main>

      {showDeleteDialog && (
        <DeleteAccountDialog onClose={() => setShowDeleteDialog(false)} />
      )}
    </div>
  );
}