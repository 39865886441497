import axios from 'axios';

interface GeoLocation {
  country: string;
  region: string;
  city: string;
  language: string;
}

export async function detectUserLocation(): Promise<GeoLocation> {
  try {
    // Primeiro tenta usar a API de geolocalização do navegador
    if (navigator.geolocation) {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      // Usa as coordenadas para obter o país
      const response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`
      );
      
      return {
        country: response.data.countryCode,
        region: response.data.principalSubdivision,
        city: response.data.locality,
        language: response.data.countryCode === 'BR' ? 'pt-BR' : 'en'
      };
    }

    // Fallback para detecção por IP
    const response = await axios.get('https://ipapi.co/json/');
    return {
      country: response.data.country_code,
      region: response.data.region,
      city: response.data.city,
      language: response.data.country_code === 'BR' ? 'pt-BR' : 'en'
    };
  } catch (error) {
    console.error('Error detecting location:', error);
    // Default para inglês se a detecção falhar
    return {
      country: 'US',
      region: '',
      city: '',
      language: 'en'
    };
  }
}