import { useState, useEffect } from 'react';
import { fetchStories, type Story } from '../utils/stories';
import toast from 'react-hot-toast';

export function useInfiniteStories() {
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadStories = async () => {
      try {
        setLoading(true);
        setError(null);
        const result = await fetchStories();
        setStories(result.stories);
      } catch (error) {
        console.error('Error loading stories:', error);
        setError('Failed to load stories');
        toast.error('Failed to load stories');
      } finally {
        setLoading(false);
      }
    };

    loadStories();
  }, []);

  return {
    stories,
    setStories,
    loading,
    error
  };
}