export function loadVideoPreview(videoElement: HTMLVideoElement, onPreviewLoaded: () => void) {
  // Save original source
  const originalSrc = videoElement.src;
  
  // Reset video element
  videoElement.removeAttribute('src');
  videoElement.load();

  // Set up preview loading
  videoElement.addEventListener('loadedmetadata', function onMetadata() {
    // Remove event listener to prevent memory leaks
    videoElement.removeEventListener('loadedmetadata', onMetadata);
    
    // Set to 1 second
    videoElement.currentTime = 1;
  }, { once: true });

  videoElement.addEventListener('seeked', function onSeeked() {
    // Remove event listener
    videoElement.removeEventListener('seeked', onSeeked);
    
    // Notify that preview is loaded
    onPreviewLoaded();

    // Reset source to original for full video playback
    videoElement.src = originalSrc;
    videoElement.load();
  }, { once: true });

  // Start loading the video
  videoElement.src = originalSrc;
  videoElement.load();
}