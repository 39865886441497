import { doc, deleteDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../lib/firebase';
import toast from 'react-hot-toast';

const ADMIN_EMAIL = 'afonsoalcantara@outlook.com';

export async function isAdmin(email: string | null): Promise<boolean> {
  return email?.toLowerCase() === ADMIN_EMAIL.toLowerCase();
}

export async function deleteAnyCommunity(communityId: string, currentUserEmail: string | null) {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete community image if exists
    try {
      const imageRef = ref(storage, `communities/${communityId}`);
      await deleteObject(imageRef);
    } catch (error) {
      console.error('Error deleting community image:', error);
    }

    // Delete all topics and their responses
    const topicsRef = collection(db, 'communities', communityId, 'topics');
    const topicsSnapshot = await getDocs(topicsRef);
    
    for (const topicDoc of topicsSnapshot.docs) {
      // Delete all responses for each topic
      const responsesRef = collection(db, 'communities', communityId, 'topics', topicDoc.id, 'responses');
      const responsesSnapshot = await getDocs(responsesRef);
      await Promise.all(responsesSnapshot.docs.map(doc => deleteDoc(doc.ref)));
      
      // Delete the topic
      await deleteDoc(topicDoc.ref);
    }

    // Delete the community document
    await deleteDoc(doc(db, 'communities', communityId));

    toast.success('Community deleted by admin');
  } catch (error) {
    console.error('Error deleting community:', error);
    throw error;
  }
}