import { useState, useEffect, useRef } from 'react';
import { Search, X, Loader2, Music, Play, Pause } from 'lucide-react';
import { SpotifyApi } from '@spotify/web-api-ts-sdk';
import toast from 'react-hot-toast';

interface SpotifySearchProps {
  onSelect: (track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  }) => void;
  onClose: () => void;
}

const SPOTIFY_CLIENT_ID = 'b5063a74aa6f41018315fe19fd3c7cb8';
const SPOTIFY_CLIENT_SECRET = 'c420550cced749d6966af287bb092a89';

export function SpotifySearch({ onSelect, onClose }: SpotifySearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [tracks, setTracks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [spotify, setSpotify] = useState<SpotifyApi | null>(null);
  const [playingTrackId, setPlayingTrackId] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const initializeSpotify = async () => {
      try {
        const api = SpotifyApi.withClientCredentials(
          SPOTIFY_CLIENT_ID,
          SPOTIFY_CLIENT_SECRET
        );
        setSpotify(api);
      } catch (error) {
        console.error('Error initializing Spotify:', error);
        toast.error('Failed to connect to Spotify');
      }
    };

    initializeSpotify();

    // Cleanup audio on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const searchTracks = async () => {
    if (!searchQuery.trim() || !spotify) return;

    try {
      setLoading(true);
      const response = await spotify.search(searchQuery, ['track'], undefined, 50); // Increased limit to get more results
      
      // Filter tracks to only include those with preview URLs
      const tracksWithPreview = response.tracks.items.filter(track => track.preview_url);
      setTracks(tracksWithPreview);

      if (tracksWithPreview.length === 0 && response.tracks.items.length > 0) {
        toast.error('No previewable tracks found for this search');
      }
    } catch (error) {
      console.error('Error searching tracks:', error);
      toast.error('Failed to search tracks');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        searchTracks();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSelect = (track: any) => {
    onSelect({
      id: track.id,
      name: track.name,
      artists: track.artists.map((artist: any) => artist.name),
      albumName: track.album.name,
      albumImage: track.album.images[0]?.url,
      previewUrl: track.preview_url,
      spotifyUrl: track.external_urls.spotify
    });
    onClose();
  };

  const togglePreview = (track: any) => {
    if (!track.preview_url) {
      toast.error('No preview available for this song');
      return;
    }

    if (playingTrackId === track.id) {
      // Stop current preview
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      setPlayingTrackId(null);
    } else {
      // Stop any playing preview
      if (audioRef.current) {
        audioRef.current.pause();
      }

      // Start new preview
      const audio = new Audio(track.preview_url);
      audio.volume = 0.5;
      
      audio.onended = () => {
        setPlayingTrackId(null);
        audioRef.current = null;
      };

      audio.play().catch(error => {
        console.error('Error playing preview:', error);
        toast.error('Failed to play preview');
      });

      audioRef.current = audio;
      setPlayingTrackId(track.id);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-semibold flex items-center">
            <Music className="h-5 w-5 mr-2 text-green-500" />
            Search Music
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="relative mb-4">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for songs..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              autoFocus
            />
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-green-500" />
            </div>
          ) : tracks.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              {tracks.map((track) => (
                <div
                  key={track.id}
                  className="flex items-center space-x-3 p-3 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  <div className="relative">
                    <img
                      src={track.album.images[0]?.url}
                      alt={track.album.name}
                      className="w-12 h-12 rounded-lg"
                    />
                    <button
                      onClick={() => togglePreview(track)}
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-50 transition-opacity rounded-lg group"
                    >
                      {playingTrackId === track.id ? (
                        <Pause className="h-6 w-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                      ) : (
                        <Play className="h-6 w-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                      )}
                    </button>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="font-medium truncate">{track.name}</p>
                    <p className="text-sm text-gray-500 truncate">
                      {track.artists.map((artist: any) => artist.name).join(', ')}
                    </p>
                  </div>
                  <button
                    onClick={() => handleSelect(track)}
                    className="px-3 py-1 bg-green-500 text-white text-sm rounded-full hover:bg-green-600 transition-colors"
                  >
                    Select
                  </button>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <p className="text-center text-gray-500 py-8">No previewable songs found</p>
          ) : (
            <p className="text-center text-gray-500 py-8">
              Search for songs with preview available
            </p>
          )}
        </div>
      </div>
    </div>
  );
}