import { User, deleteUser } from 'firebase/auth';
import { doc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import toast from 'react-hot-toast';

export async function deleteUserAccount(user: User) {
  try {
    // Delete user's posts
    const postsRef = collection(db, 'posts');
    const postsQuery = query(postsRef, where('authorId', '==', user.uid));
    const postsSnapshot = await getDocs(postsQuery);
    
    for (const postDoc of postsSnapshot.docs) {
      // Delete post media
      try {
        const mediaRef = ref(storage, `posts/${user.uid}/${postDoc.id}`);
        const mediaFiles = await listAll(mediaRef);
        await Promise.all(mediaFiles.items.map(file => deleteObject(file)));
      } catch (error) {
        console.error('Error deleting post media:', error);
      }
      await deleteDoc(postDoc.ref);
    }

    // Delete user's stories
    const storiesRef = collection(db, 'stories');
    const storiesQuery = query(storiesRef, where('authorId', '==', user.uid));
    const storiesSnapshot = await getDocs(storiesQuery);
    
    for (const storyDoc of storiesSnapshot.docs) {
      try {
        const storyRef = ref(storage, `stories/${storyDoc.id}`);
        await deleteObject(storyRef);
      } catch (error) {
        console.error('Error deleting story media:', error);
      }
      await deleteDoc(storyDoc.ref);
    }

    // Delete user's profile photo
    try {
      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await deleteObject(avatarRef);
    } catch (error) {
      console.error('Error deleting avatar:', error);
    }

    // Delete user's notifications
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(
      notificationsRef,
      where('recipientId', '==', user.uid)
    );
    const notificationsSnapshot = await getDocs(notificationsQuery);
    await Promise.all(notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete user document
    await deleteDoc(doc(db, 'users', user.uid));

    // Finally, delete the auth user
    await deleteUser(user);

    toast.success('Your account has been deleted');
  } catch (error: any) {
    console.error('Error deleting account:', error);
    throw new Error(error.message || 'Failed to delete account');
  }
}