import { Loader2 } from 'lucide-react';
import type { YouTubeVideo } from '../../utils/youtube';

interface VideoGridProps {
  videos: YouTubeVideo[];
  loading: boolean;
  onVideoSelect: (videoId: string) => void;
}

export function VideoGrid({ videos, loading, onVideoSelect }: VideoGridProps) {
  const getVideoId = (video: YouTubeVideo): string => {
    return typeof video.id === 'string' ? video.id : video.id.videoId;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
      {videos.map((video) => (
        <div
          key={getVideoId(video)}
          className="cursor-pointer hover:bg-gray-50 rounded-lg overflow-hidden transition-colors"
          onClick={() => onVideoSelect(getVideoId(video))}
        >
          <img
            src={video.snippet.thumbnails.high.url}
            alt={video.snippet.title}
            className="w-full aspect-video object-cover"
          />
          <div className="p-3">
            <h3 className="font-medium line-clamp-2">{video.snippet.title}</h3>
            <p className="text-sm text-gray-500 mt-1">{video.snippet.channelTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
}