import { useState, useRef } from 'react';
import { Heart, X } from 'lucide-react';
import { Profile } from '../../types/match';
import { MatchBanner } from './MatchBanner';
import { Link } from 'react-router-dom';

interface MatchCardProps {
  profile: Profile;
  onSwipe: (liked: boolean) => Promise<void>;
}

const SWIPE_THRESHOLD = 100;

export function MatchCard({ profile, onSwipe }: MatchCardProps) {
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [showBanner, setShowBanner] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    const point = 'touches' in e ? e.touches[0] : e;
    setDragStart({ x: point.clientX, y: point.clientY });
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!dragStart) return;
    
    const point = 'touches' in e ? e.touches[0] : e;
    const newOffset = {
      x: point.clientX - dragStart.x,
      y: point.clientY - dragStart.y
    };
    setOffset(newOffset);
  };

  const handleDragEnd = async () => {
    if (!dragStart) return;

    if (Math.abs(offset.x) > SWIPE_THRESHOLD) {
      const liked = offset.x > 0;
      if (liked) {
        setShowBanner(true);
      }
      await onSwipe(liked);
    }

    setDragStart(null);
    setOffset({ x: 0, y: 0 });
  };

  const rotation = (offset.x / SWIPE_THRESHOLD) * 20;
  const scale = Math.max(1 - Math.abs(offset.x) / 1000, 0.9);
  const opacity = Math.max(1 - Math.abs(offset.x) / 500, 0.5);

  return (
    <div
      ref={cardRef}
      className="absolute w-full max-w-sm bg-white rounded-xl shadow-lg overflow-hidden cursor-grab active:cursor-grabbing"
      style={{
        transform: `rotate(${rotation}deg) scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
        opacity,
        transition: dragStart ? 'none' : 'all 0.5s ease'
      }}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      {showBanner && (
        <MatchBanner photoURL={profile.photoURL} name={profile.name} />
      )}

      <Link to={`/profile/${profile.username}`}>
        <img
          src={profile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${profile.uid}`}
          alt={profile.name}
          className="w-full aspect-square object-cover"
        />
      </Link>
      
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
        <h2 className="text-2xl font-bold text-white mb-1">
          {profile.name}
        </h2>
        <p className="text-white/80">@{profile.username}</p>
        {profile.bio && (
          <p className="text-white/70 mt-2 line-clamp-2">{profile.bio}</p>
        )}
      </div>

      <div 
        className="absolute top-4 left-4 bg-green-500 text-white px-4 py-2 rounded-full transform rotate-[-20deg]"
        style={{ opacity: offset.x > SWIPE_THRESHOLD ? 1 : 0 }}
      >
        <Heart className="h-6 w-6 fill-current" />
      </div>
      <div 
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-full transform rotate-[20deg]"
        style={{ opacity: offset.x < -SWIPE_THRESHOLD ? 1 : 0 }}
      >
        <X className="h-6 w-6" />
      </div>
    </div>
  );
}