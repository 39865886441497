// Utility functions for managing blocked users
const BLOCKED_USERS_KEY = 'poosting_blocked_users';

interface BlockedUsers {
  [userId: string]: string[]; // userId -> array of blocked user IDs
}

export function getBlockedUsers(userId: string): string[] {
  try {
    const blocked = localStorage.getItem(BLOCKED_USERS_KEY);
    if (!blocked) return [];
    
    const blockedUsers: BlockedUsers = JSON.parse(blocked);
    return blockedUsers[userId] || [];
  } catch (error) {
    console.error('Error getting blocked users:', error);
    return [];
  }
}

export function blockUser(userId: string, blockedUserId: string): void {
  try {
    const blocked = localStorage.getItem(BLOCKED_USERS_KEY);
    const blockedUsers: BlockedUsers = blocked ? JSON.parse(blocked) : {};
    
    if (!blockedUsers[userId]) {
      blockedUsers[userId] = [];
    }
    
    if (!blockedUsers[userId].includes(blockedUserId)) {
      blockedUsers[userId].push(blockedUserId);
    }
    
    localStorage.setItem(BLOCKED_USERS_KEY, JSON.stringify(blockedUsers));
  } catch (error) {
    console.error('Error blocking user:', error);
  }
}

export function unblockUser(userId: string, blockedUserId: string): void {
  try {
    const blocked = localStorage.getItem(BLOCKED_USERS_KEY);
    if (!blocked) return;
    
    const blockedUsers: BlockedUsers = JSON.parse(blocked);
    if (!blockedUsers[userId]) return;
    
    blockedUsers[userId] = blockedUsers[userId].filter(id => id !== blockedUserId);
    localStorage.setItem(BLOCKED_USERS_KEY, JSON.stringify(blockedUsers));
  } catch (error) {
    console.error('Error unblocking user:', error);
  }
}

export function isUserBlocked(userId: string, targetUserId: string): boolean {
  const blockedUsers = getBlockedUsers(userId);
  return blockedUsers.includes(targetUserId);
}