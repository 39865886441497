import { LogOut } from 'lucide-react';
import { auth } from '../lib/firebase';
import { BottomNav } from '../components/BottomNav';

export function TermsOfUse() {
  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-6">
        <article className="prose prose-sm max-w-none">
          <h1 className="text-2xl font-bold mb-4">Terms of Use (EULA) for Poosting</h1>
          <p className="text-sm text-gray-500 mb-6">Last Updated: 17.12.24</p>

          <div className="mb-8">
            <p className="font-medium">Welcome to Poosting!</p>
            <p>By accessing or using our platform, you agree to the following Terms of Use. If you do not agree, please refrain from using Poosting.</p>
          </div>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">1. Definitions</h2>
            <p>1.1 Poosting: Refers to the social networking platform where users can share photos, videos, audio, and other content.</p>
            <p>1.2 User: Any individual who creates an account and uses the Poosting platform.</p>
            <p>1.3 Content: Refers to all forms of material, including text, images, videos, and audio, posted by users.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">2. User Accounts</h2>
            <p>2.1 You must create an account with accurate and truthful information to access Poosting.</p>
            <p>2.2 You are responsible for maintaining the security of your account, including your password, and for all activities that occur under your account.</p>
            <p>2.3 Impersonating others or creating false accounts is strictly prohibited.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">3. Acceptable Use</h2>
            <p>3.1 Users must not publish, share, or promote content that:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Violates laws or regulations.</li>
              <li>Is offensive, discriminatory, defamatory, or harassing.</li>
              <li>Promotes violence, hate speech, or illegal activities.</li>
            </ul>
            <p>3.2 Spamming, phishing, or engaging in fraudulent activities is not allowed.</p>
            <p>3.3 There is no tolerance for objectionable content or abusive behavior. Users who violate these policies will be removed without prior notice.</p>
            <p>3.4 Violation of these rules may lead to account suspension or termination without prior notice.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">4. User Content and Intellectual Property</h2>
            <p>4.1 You retain ownership of the content you post on Poosting.</p>
            <p>4.2 By posting content, you grant Poosting a worldwide, non-exclusive, royalty-free license to use, display, and distribute your content for platform operations and promotional purposes.</p>
            <p>4.3 You are solely responsible for ensuring your content does not infringe on the intellectual property rights of others.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">5. Privacy and Data Usage</h2>
            <p>5.1 By using Poosting, you agree to our Privacy Policy regarding the collection, use, and storage of personal data.</p>
            <p>5.2 We employ encryption and other security measures to protect user data but cannot guarantee absolute security.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">6. Platform Availability</h2>
            <p>6.1 Poosting strives to provide uninterrupted access but does not guarantee the platform will always be available, secure, or error-free.</p>
            <p>6.2 We reserve the right to modify, suspend, or discontinue any part of the platform without prior notice.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">7. Limitation of Liability</h2>
            <p>7.1 Poosting is not liable for user-generated content or any damages resulting from the use of the platform.</p>
            <p>7.2 The platform is provided "as is," and we disclaim all warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">8. Amendments to the Terms</h2>
            <p>8.1 These Terms of Use may be updated periodically.</p>
            <p>8.2 Users will be notified of significant changes, and continued use of Poosting implies acceptance of the updated terms.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold">9. Contact Us</h2>
            <p>For questions or support, contact us at:</p>
            <p>Email: <a href="mailto:contact@poosting.com" className="text-blue-500 hover:text-blue-600">contact@poosting.com</a></p>
          </section>
        </article>
      </main>

      <BottomNav />
    </div>
  );
}