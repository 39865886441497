const BASE_URL = 'https://de1.api.radio-browser.info/json';

export async function fetchRadioStations() {
  try {
    // Fetch stations from multiple endpoints in parallel
    const [topClick, topVote, trending] = await Promise.all([
      fetch(`${BASE_URL}/stations/topclick/300`),
      fetch(`${BASE_URL}/stations/topvote/300`),
      fetch(`${BASE_URL}/stations/lastclick/300`)
    ]);

    const [topClickData, topVoteData, trendingData] = await Promise.all([
      topClick.json(),
      topVote.json(),
      trending.json()
    ]);

    // Combine and deduplicate stations
    const allStations = [...topClickData, ...topVoteData, ...trendingData];
    const uniqueStations = new Map();

    allStations.forEach(station => {
      if (station.url_resolved && station.name && !uniqueStations.has(station.url_resolved)) {
        uniqueStations.set(station.url_resolved, {
          name: station.name,
          url: station.url_resolved,
          favicon: station.favicon || `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`,
          country: station.country || 'Unknown',
          language: station.language || 'Unknown',
          tags: station.tags || '',
          votes: station.votes || 0,
          clickCount: station.clickcount || 0
        });
      }
    });

    return Array.from(uniqueStations.values());
  } catch (error) {
    console.error('Error fetching radio stations:', error);
    throw new Error('Failed to load radio stations');
  }
}