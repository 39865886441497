import { BlockedUsers } from './types';
import { getBlockedUsersFromStorage, saveBlockedUsers } from './storage';

export function getBlockedUsers(userId: string): string[] {
  const blockedUsers = getBlockedUsersFromStorage();
  return blockedUsers[userId] || [];
}

export function blockUser(userId: string, blockedUserId: string): void {
  try {
    const blockedUsers = getBlockedUsersFromStorage();
    
    if (!blockedUsers[userId]) {
      blockedUsers[userId] = [];
    }
    
    if (!blockedUsers[userId].includes(blockedUserId)) {
      blockedUsers[userId].push(blockedUserId);
      saveBlockedUsers(blockedUsers);
    }
  } catch (error) {
    console.error('Error blocking user:', error);
  }
}

export function unblockUser(userId: string, blockedUserId: string): void {
  try {
    const blockedUsers = getBlockedUsersFromStorage();
    if (!blockedUsers[userId]) return;
    
    blockedUsers[userId] = blockedUsers[userId].filter(id => id !== blockedUserId);
    saveBlockedUsers(blockedUsers);
  } catch (error) {
    console.error('Error unblocking user:', error);
  }
}

export function isUserBlocked(userId: string, targetUserId: string): boolean {
  const blockedUsers = getBlockedUsers(userId);
  return blockedUsers.includes(targetUserId);
}