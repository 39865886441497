import { useState, useEffect } from 'react';
import { auth, storage, db, analyticsEvents } from '../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  fetchSignInMethodsForEmail,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence
} from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { ImageUpload } from '../components/ImageUpload';
import { AuthInput } from '../components/AuthInput';
import { isValidEmail, isValidUsername, isValidPassword } from '../utils/validation';
import { addDeviceRegistration } from '../utils/deviceLimit';

export function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('@');
  const [bio, setBio] = useState('');
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (value: string) => {
    if (!value.startsWith('@')) {
      setUsername('@' + value);
    } else {
      setUsername(value);
    }
  };

  const handleBioChange = (value: string) => {
    // Remove URLs from bio text
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br))/gi;
    const cleanBio = value.replace(urlRegex, '');
    setBio(cleanBio);
  };

  const handleImageSelect = (file: File) => {
    setProfileImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const checkUsername = async (username: string) => {
    if (!username || !isValidUsername(username.slice(1))) {
      setIsUsernameAvailable(null);
      return;
    }

    setIsCheckingUsername(true);
    try {
      const cleanUsername = username.slice(1).toLowerCase();
      const q = query(
        collection(db, 'users'),
        where('username', '==', cleanUsername)
      );
      const snapshot = await getDocs(q);
      setIsUsernameAvailable(snapshot.empty);
    } catch (error) {
      console.error('Error checking username:', error);
      setIsUsernameAvailable(false);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const checkEmail = async (email: string) => {
    if (!email || !isValidEmail(email)) {
      setIsEmailAvailable(null);
      return;
    }

    setIsCheckingEmail(true);
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      setIsEmailAvailable(methods.length === 0);
      
      if (methods.length > 0) {
        toast.error('This email is already registered');
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const debouncedCheckUsername = debounce(checkUsername, 500);
  const debouncedCheckEmail = debounce(checkEmail, 500);

  useEffect(() => {
    if (username.length > 1) {
      debouncedCheckUsername(username);
    }
    return () => debouncedCheckUsername.cancel();
  }, [username]);

  useEffect(() => {
    if (email) {
      debouncedCheckEmail(email);
    }
    return () => debouncedCheckEmail.cancel();
  }, [email]);

  const validateSignUpForm = () => {
    if (!name.trim()) {
      toast.error('Please enter your name');
      return false;
    }
    if (!isValidUsername(username.slice(1))) {
      toast.error('Username must be 3-20 characters and contain only letters, numbers, and underscores');
      return false;
    }
    if (!isUsernameAvailable) {
      toast.error('Username is not available');
      return false;
    }
    if (!termsAccepted) {
      toast.error('Please accept the terms of use and cookie policy');
      return false;
    }
    if (!isAdult) {
      toast.error('You must be 18 years or older to create an account');
      return false;
    }
    if (!profileImage) {
      toast.error('Please upload a profile photo');
      return false;
    }
    if (!isValidEmail(email)) {
      toast.error('Please enter a valid email address');
      return false;
    }
    if (!isEmailAvailable) {
      toast.error('Email is already in use');
      return false;
    }
    if (!isValidPassword(password)) {
      toast.error('Password must be at least 6 characters long');
      return false;
    }
    return true;
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidEmail(email) || !password || loading) return;

    setLoading(true);
    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      analyticsEvents.login();
      toast.success('Welcome back!');
      navigate('/');
    } catch (error: any) {
      console.error('Sign in error:', error);
      toast.error(error.message || 'Authentication failed');
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateSignUpForm() || loading) return;

    // Check device limit
    if (!addDeviceRegistration(email)) {
      return; // Device limit reached
    }

    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      let photoURL = '';
      
      if (profileImage) {
        const storageRef = ref(storage, `avatars/${user.uid}`);
        await uploadBytes(storageRef, profileImage);
        photoURL = await getDownloadURL(storageRef);
      }

      const cleanUsername = username.slice(1).toLowerCase();

      // Create user document
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: name.trim(),
        username: cleanUsername,
        displayName: cleanUsername,
        photoURL,
        bio: bio.trim(),
        createdAt: serverTimestamp(),
        allies: [],
        likes: [],
        stars: [],
        trash: [],
        coins: 10,
        previousAlliesCount: 0
      });

      await updateProfile(user, {
        displayName: username,
        photoURL,
      });

      analyticsEvents.signup();
      toast.success('Account created successfully!');
      navigate('/');
    } catch (error: any) {
      console.error('Sign up error:', error);
      toast.error(error.message || 'Failed to create account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mx-auto space-y-8">
        <div>
          <h1 className="text-center text-4xl font-bold text-black">Poosting</h1>
          <p className="mt-2 text-center text-lg text-gray-600">
            Post Anonymous or not for World!
          </p>
        </div>

        {/* Sign In Form */}
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center mb-6">Sign In</h2>
          <form onSubmit={handleSignIn} className="space-y-4">
            <AuthInput
              id="signin-email"
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              placeholder="Email address"
            />
            <AuthInput
              id="signin-password"
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder="Password"
            />
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                Remember me
              </label>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
            >
              {loading ? <Loader2 className="h-5 w-5 animate-spin" /> : 'Sign in'}
            </button>
          </form>
        </div>

        {/* Sign Up Form */}
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center mb-6">Create Account</h2>
          <form onSubmit={handleSignUp} className="space-y-6">
            <div className="text-center mb-6">
              <p className="text-sm font-medium text-gray-700 mb-2">Profile Photo</p>
              <ImageUpload
                imagePreview={imagePreview}
                onImageSelect={handleImageSelect}
              />
            </div>

            <AuthInput
              id="name"
              type="text"
              label="Name"
              value={name}
              onChange={setName}
              placeholder="Full name"
              maxLength={30}
            />

            <div>
              <p className="text-sm font-medium text-gray-700 mb-2">Username</p>
              <AuthInput
                id="username"
                type="text"
                label="Username"
                value={username}
                onChange={handleUsernameChange}
                placeholder="@username"
                isChecking={isCheckingUsername}
                isValid={isUsernameAvailable}
                maxLength={20}
              />
            </div>

            <AuthInput
              id="bio"
              type="text"
              label="Bio"
              value={bio}
              onChange={handleBioChange}
              placeholder="Tell us about yourself"
              maxLength={120}
              isTextarea={true}
            />

            <AuthInput
              id="signup-email"
              type="email"
              label="Email"
              value={email}
              onChange={setEmail}
              placeholder="Email address"
              isChecking={isCheckingEmail}
              isValid={isEmailAvailable}
            />

            <AuthInput
              id="signup-password"
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder="Password"
            />

            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="age-verification"
                    name="age-verification"
                    type="checkbox"
                    checked={isAdult}
                    onChange={(e) => setIsAdult(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
                    required
                  />
                </div>
                <div className="ml-3">
                  <label htmlFor="age-verification" className="text-sm text-gray-600">
                    I am 18 years or older
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className="text-gray-600">
                    I agree with Poosting's{' '}
                    <a
                      href="https://poosting.com.br/doc/en/poosting-terms-of-use.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      terms of use
                    </a>
                    {' '}and{' '}
                    <a
                      href="https://poosting.com.br/doc/en/cookie-policy.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      cookie policy
                    </a>
                    {' '}and{' '}
                    <a
                      href="https://poosting.com.br/doc/en/privacy-policy.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      privacy policy
                    </a>
                  </label>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
            >
              {loading ? <Loader2 className="h-5 w-5 animate-spin" /> : 'Create Account'}
            </button>
          </form>
        </div>

        <div className="text-center space-y-2">
          <p className="text-xs text-gray-500">
            Created by CEO - Afonso Alcântara
          </p>
          <a
            href="https://poosting.com.br/doc/en/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
            className="block text-xs text-blue-600 hover:text-blue-500"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}