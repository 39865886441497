import { useState } from 'react';
import { Play, Pause } from 'lucide-react';

interface SpotifyTrackProps {
  track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
}

export function SpotifyTrack({ track }: SpotifyTrackProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio(track.previewUrl));

  const handlePlayPause = () => {
    if (!track.previewUrl) {
      window.open(track.spotifyUrl, '_blank');
      return;
    }

    if (isPlaying) {
      audio.pause();
    } else {
      audio.currentTime = 0;
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle audio ending
  audio.onended = () => setIsPlaying(false);

  return (
    <div className="mb-3">
      <div className="relative w-full aspect-square rounded-lg overflow-hidden group">
        <img
          src={track.albumImage}
          alt={track.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
          <h3 className="text-2xl font-bold text-center mb-2">{track.name}</h3>
          <p className="text-lg text-center mb-4">{track.artists.join(', ')}</p>
          <button
            onClick={handlePlayPause}
            className="p-4 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
          >
            {isPlaying ? (
              <Pause className="h-8 w-8" />
            ) : (
              <Play className="h-8 w-8" />
            )}
          </button>
          {!track.previewUrl && (
            <p className="mt-2 text-sm opacity-75">No preview available - Click to open in Spotify</p>
          )}
        </div>
      </div>
    </div>
  );
}