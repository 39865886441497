import { useState, useEffect, useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { Eye, MoreHorizontal, Loader2, Trash2 } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { PostVotes } from './PostVotes';
import { PostMatch } from './PostMatch';
import { Comments } from './Comments';
import { LocationMessage } from './LocationMessage';
import { isContentBlocked, isUserBlocked } from '../utils/blocking';
import { PostMenu } from './PostMenu';
import { PostContent } from './PostContent';
import { PostHeader } from './PostHeader';
import { PostFooter } from './PostFooter';
import { PostLifetime } from './PostLifetime';
import { PollView } from './PollView';
import { doc, updateDoc, increment, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import toast from 'react-hot-toast';

interface Poll {
  question: string;
  options: {
    text: string;
    votes: string[];
  }[];
  totalVotes: number;
  endTime: Date;
}

interface PostProps {
  id: string;
  content: string;
  imageUrl?: string;
  videoUrl?: string;
  audioUrl?: string;
  location?: {
    latitude: number;
    longitude: number;
  };
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
  youtubeVideo?: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
  poll?: Poll;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  likes?: string[];
  stars?: string[];
  trash?: string[];
  matches?: string[];
  views?: number;
  allies?: string[];
  onDelete?: () => void;
}

export function Post(props: PostProps) {
  const { user } = useAuth();
  const { userPhoto } = usePhoto();
  const [isHidden, setIsHidden] = useState(false);
  const [hasViewed, setHasViewed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const postRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!user) return;

    // Check if content or author is blocked
    const contentBlocked = isContentBlocked(user.uid, props.id);
    const authorBlocked = isUserBlocked(user.uid, props.authorId);
    setIsHidden(contentBlocked || authorBlocked);

    // Check if user has already voted in poll
    if (props.poll) {
      const voted = props.poll.options.some(option => 
        option.votes.includes(user.uid)
      );
      setHasVoted(voted);
    }
  }, [user, props.id, props.authorId, props.poll]);

  useEffect(() => {
    // Check if post is older than 24 hours
    const now = new Date().getTime();
    const postTime = props.timestamp.getTime();
    const timeDiff = now - postTime;
    const hoursOld = timeDiff / (1000 * 60 * 60);
    
    if (hoursOld > 24) {
      setIsExpired(true);
      setIsHidden(true);
    }
  }, [props.timestamp]);

  useEffect(() => {
    if (!postRef.current || hasViewed || !user || isExpired) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasViewed) {
            updateDoc(doc(db, 'posts', props.id), {
              views: increment(1)
            }).catch(error => {
              console.error('Error updating view count:', error);
            });
            setHasViewed(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(postRef.current);
    return () => observer.disconnect();
  }, [props.id, hasViewed, user, isExpired]);

  const handleVote = async (optionIndex: number) => {
    if (!user || !props.poll || hasVoted || isExpired) return;

    try {
      const postRef = doc(db, 'posts', props.id);
      const updatedOptions = [...props.poll.options];
      updatedOptions[optionIndex].votes.push(user.uid);

      await updateDoc(postRef, {
        'poll.options': updatedOptions,
        'poll.totalVotes': increment(1)
      });

      setHasVoted(true);
      toast.success('Vote recorded successfully');
    } catch (error) {
      console.error('Error voting in poll:', error);
      toast.error('Failed to record vote');
    }
  };

  const handleDelete = async () => {
    if (!user || user.uid !== props.authorId || isDeleting) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this post? This action cannot be undone.');
    if (!confirmDelete) return;

    setIsDeleting(true);

    try {
      // Delete post media from storage if exists
      if (props.imageUrl || props.videoUrl || props.audioUrl) {
        const storageRef = ref(storage, `posts/${props.authorId}/${props.id}`);
        try {
          const mediaFiles = await listAll(storageRef);
          await Promise.all(mediaFiles.items.map(file => deleteObject(file)));
        } catch (error) {
          console.error('Error deleting media files:', error);
        }
      }

      // Delete post comments
      const commentsRef = collection(db, 'posts', props.id, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      await Promise.all(commentsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      // Delete post notifications
      const notificationsRef = collection(db, 'notifications');
      const notificationsQuery = query(notificationsRef, where('postId', '==', props.id));
      const notificationsSnapshot = await getDocs(notificationsQuery);
      await Promise.all(notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      // Delete the post document
      await deleteDoc(doc(db, 'posts', props.id));

      toast.success('Post deleted successfully');
      if (props.onDelete) {
        props.onDelete();
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isHidden || isExpired) {
    return null;
  }

  const isAdmin = user?.displayName?.toLowerCase().replace('@', '') === 'afonsoalcantara';
  const isAuthor = user?.uid === props.authorId;

  return (
    <article ref={postRef} className="bg-white">
      <div className="p-4">
        <div className="flex items-start justify-between mb-4">
          <PostHeader
            id={props.id}
            authorId={props.authorId}
            authorName={props.authorName}
            authorUsername={props.authorUsername}
            authorPhotoURL={props.authorPhotoURL}
            timestamp={props.timestamp}
            allies={props.allies}
            isAdmin={isAdmin}
          />
          {isAuthor && (
            <button
              onClick={handleDelete}
              disabled={isDeleting}
              className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors disabled:opacity-50"
              title="Delete post"
            >
              {isDeleting ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <Trash2 className="h-5 w-5" />
              )}
            </button>
          )}
        </div>

        <PostContent
          content={props.content}
          imageUrl={props.imageUrl}
          videoUrl={props.videoUrl}
          audioUrl={props.audioUrl}
          location={props.location}
          spotifyTrack={props.spotifyTrack}
          youtubeVideo={props.youtubeVideo}
          authorName={props.authorName}
          authorPhotoURL={props.authorPhotoURL}
        />

        {props.poll && (
          <PollView
            poll={{
              ...props.poll,
              endTime: props.poll.endTime instanceof Date 
                ? props.poll.endTime 
                : new Date(props.poll.endTime)
            }}
            onVote={handleVote}
            hasVoted={hasVoted}
            isExpired={new Date() > new Date(props.poll.endTime)}
          />
        )}

        <PostLifetime timestamp={props.timestamp} />

        <PostFooter
          id={props.id}
          authorId={props.authorId}
          likes={props.likes || []}
          stars={props.stars || []}
          trash={props.trash || []}
          matches={props.matches || []}
          views={props.views || 0}
          onBlock={() => setIsHidden(true)}
        />
      </div>

      <Comments postId={props.id} authorId={props.authorId} />
    </article>
  );
}