import { useState, useEffect } from 'react';
import { Search as SearchIcon, Loader2, LogOut } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { auth } from '../lib/firebase';
import { debounce } from 'lodash';
import { searchUsers } from '../utils/search';
import { isUserBlocked } from '../utils/blockUser';
import type { User } from '../types/user';
import { SearchInput } from '../components/SearchInput';
import { UserList } from '../components/UserList';
import { EmptySearchState } from '../components/EmptySearchState';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

export function Search() {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const { user: currentUser } = useAuth();

  const performSearch = async (query: string) => {
    if (!query.trim() || !currentUser) return;

    try {
      setLoading(true);
      const results = await searchUsers(query, currentUser.uid);
      
      // Filter out blocked users
      const filteredResults = results.filter(user => 
        !isUserBlocked(currentUser.uid, user.uid)
      );
      
      setUsers(filteredResults);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error('Failed to search users');
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(performSearch, 300);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    } else {
      setUsers([]);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, currentUser?.uid]);

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <SearchIcon className="h-6 w-6 text-gray-400" />
            <h1 className="text-xl font-bold">Search Users</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <SearchInput value={searchQuery} onChange={setSearchQuery} />
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        ) : users.length > 0 ? (
          <UserList users={users} />
        ) : (
          <EmptySearchState searchQuery={searchQuery} />
        )}
      </main>
    </div>
  );
}