import { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, where, getDocs, updateDoc, doc, limit, startAfter } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import { Comment } from './Comment';
import { Loader2, MessageCircle } from 'lucide-react';
import toast from 'react-hot-toast';

interface CommentsProps {
  postId: string;
  authorId: string;
}

interface CommentType {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  postId: string;
}

const MAX_COMMENT_LENGTH = 120;
const COMMENTS_PER_PAGE = 10;

export function Comments({ postId, authorId }: CommentsProps) {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchCommentCount = async () => {
      try {
        const commentsRef = collection(db, 'posts', postId, 'comments');
        const snapshot = await getDocs(commentsRef);
        setCommentCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching comment count:', error);
      }
    };

    fetchCommentCount();
  }, [postId]);

  useEffect(() => {
    if (!showComments) return;

    const commentsRef = collection(db, 'posts', postId, 'comments');
    const q = query(
      commentsRef,
      orderBy('timestamp', 'desc'),
      limit(COMMENTS_PER_PAGE)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const commentData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
        })) as CommentType[];
        
        setComments(commentData);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === COMMENTS_PER_PAGE);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching comments:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [postId, showComments]);

  const loadMoreComments = async () => {
    if (!lastVisible || loadingMore) return;

    try {
      setLoadingMore(true);
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const q = query(
        commentsRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(COMMENTS_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      const newComments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date(),
      })) as CommentType[];

      setComments(prev => [...prev, ...newComments]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === COMMENTS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more comments:', error);
      toast.error('Failed to load more comments');
    } finally {
      setLoadingMore(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newComment.trim() || submitting) return;

    const commentContent = newComment.trim();

    // Enhanced URL detection regex
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(commentContent)) {
      toast.error('Links are not allowed in comments');
      return;
    }

    // Check for common URL patterns without protocols
    const commonUrlPatterns = /(bit\.ly|goo\.gl|t\.co|tinyurl\.com|youtu\.be)/i;
    if (commonUrlPatterns.test(commentContent)) {
      toast.error('Links are not allowed in comments');
      return;
    }

    if (commentContent.length > MAX_COMMENT_LENGTH) {
      toast.error(`Comment must be ${MAX_COMMENT_LENGTH} characters or less`);
      return;
    }

    setNewComment('');

    const optimisticComment: CommentType = {
      id: `temp-${Date.now()}`,
      content: commentContent,
      authorId: user.uid,
      authorName: user.displayName || '',
      authorUsername: user.displayName?.startsWith('@') 
        ? user.displayName.slice(1) 
        : user.displayName || '',
      authorPhotoURL: user.photoURL || '',
      timestamp: new Date(),
      postId,
    };

    if (!showComments) {
      setShowComments(true);
    }

    setComments(prev => [optimisticComment, ...prev]);
    setCommentCount(prev => prev + 1);

    try {
      setSubmitting(true);

      const commentRef = collection(db, 'posts', postId, 'comments');
      await addDoc(commentRef, {
        content: commentContent,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.startsWith('@') 
          ? user.displayName.slice(1) 
          : user.displayName || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
      });

      if (user.uid !== authorId) {
        await addDoc(collection(db, 'notifications'), {
          type: 'comment',
          postId,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.startsWith('@') 
            ? user.displayName.slice(1) 
            : user.displayName || '',
          senderPhotoURL: user.photoURL,
          timestamp: serverTimestamp(),
          read: false,
          content: commentContent,
        });
      }

      toast.success('Comment added');
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
      setComments(prev => prev.filter(comment => comment.id !== optimisticComment.id));
      setCommentCount(prev => prev - 1);
      setNewComment(commentContent);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCommentDelete = (commentId: string) => {
    setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
    setCommentCount(prev => prev - 1);
  };

  const handleLoadComments = () => {
    setLoading(true);
    setShowComments(true);
  };

  return (
    <div className="border-t border-gray-200">
      {user ? (
        <form onSubmit={handleSubmit} className="p-4 border-b border-gray-200">
          <div className="flex space-x-3">
            <img
              src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
              alt={user.displayName || 'User'}
              className="h-10 w-10 rounded-full"
            />
            <div className="flex-1">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Write a comment..."
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none ${
                  newComment.length > MAX_COMMENT_LENGTH 
                    ? 'border-red-500' 
                    : 'border-gray-300'
                }`}
                rows={2}
                maxLength={MAX_COMMENT_LENGTH}
              />
              <div className="mt-2 flex justify-between items-center">
                <span className={`text-sm ${
                  newComment.length > MAX_COMMENT_LENGTH 
                    ? 'text-red-500' 
                    : 'text-gray-500'
                }`}>
                  {newComment.length}/{MAX_COMMENT_LENGTH}
                </span>
                <button
                  type="submit"
                  disabled={!newComment.trim() || submitting || newComment.length > MAX_COMMENT_LENGTH}
                  className="px-4 py-2 bg-blue-500 text-white rounded-full font-medium hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {submitting ? 'Posting...' : 'Post'}
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="p-4 text-center text-gray-500 border-b border-gray-200">
          Please sign in to comment
        </div>
      )}

      {!showComments && commentCount > 0 && (
        <button
          onClick={handleLoadComments}
          className="w-full p-4 text-blue-500 hover:bg-gray-50 transition-colors flex items-center justify-center space-x-2"
        >
          <MessageCircle className="h-5 w-5" />
          <span>Show {commentCount} comment{commentCount !== 1 ? 's' : ''}</span>
        </button>
      )}

      {showComments && (
        <div className="divide-y divide-gray-200">
          {loading ? (
            <div className="flex justify-center p-4">
              <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
            </div>
          ) : comments.length > 0 ? (
            <>
              {comments.map((comment) => (
                <Comment
                  key={comment.id}
                  {...comment}
                  postId={postId}
                  onDelete={() => handleCommentDelete(comment.id)}
                />
              ))}
              
              {hasMore && (
                <div className="p-4 flex justify-center">
                  <button
                    onClick={loadMoreComments}
                    disabled={loadingMore}
                    className="px-4 py-2 text-blue-500 hover:bg-blue-50 rounded-full disabled:opacity-50 flex items-center space-x-2"
                  >
                    {loadingMore ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <span>Loading...</span>
                      </>
                    ) : (
                      <span>Show More</span>
                    )}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="p-4 text-center text-gray-500">
              No comments yet. Be the first to comment!
            </div>
          )}
        </div>
      )}
    </div>
  );
}