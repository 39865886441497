import YouTube from 'react-youtube';

interface YouTubeEmbedProps {
  video: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
}

export function YouTubeEmbed({ video }: YouTubeEmbedProps) {
  return (
    <div className="mb-3">
      <div className="relative w-full aspect-video rounded-lg overflow-hidden">
        <YouTube
          videoId={video.id}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              modestbranding: 1,
              rel: 0,
              playsinline: 1
            }
          }}
          className="absolute inset-0"
        />
      </div>
      <div className="mt-2">
        <h3 className="font-semibold text-gray-900">{video.title}</h3>
        <p className="text-sm text-gray-500">{video.channelTitle}</p>
      </div>
    </div>
  );
}