import { Volume2, X, Play, Pause, Loader2 } from 'lucide-react';
import { useRadio } from '../../contexts/RadioContext';

export function RadioPlaybackBar() {
  const { currentStation, isPlaying, isLoading, stopPlayback, togglePlayback } = useRadio();

  // Only render if we have a current station
  if (!currentStation) return null;

  // Truncate text to 32 characters
  const truncateText = (text: string) => {
    return text.length > 32 ? text.slice(0, 32) + '...' : text;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="max-w-2xl mx-auto flex items-center justify-between p-3">
        <div className="flex items-center space-x-3">
          <img
            src={currentStation.favicon}
            alt={currentStation.name}
            className="w-10 h-10 rounded-lg object-cover"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = `https://api.dicebear.com/7.x/initials/svg?seed=${currentStation.name}`;
            }}
          />
          <div className="flex-1 min-w-0">
            <p className="font-medium truncate">
              {truncateText(currentStation.name)}
            </p>
            <div className="flex items-center space-x-1 text-sm text-gray-500">
              <Volume2 className="h-4 w-4" />
              <span className="truncate">
                {truncateText(`${currentStation.country} • ${currentStation.language}`)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={togglePlayback}
            disabled={isLoading}
            className="p-2 text-purple-500 hover:bg-purple-50 rounded-full disabled:opacity-50"
            title={isPlaying ? "Pause" : "Play"}
          >
            {isLoading ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : isPlaying ? (
              <Pause className="h-5 w-5" />
            ) : (
              <Play className="h-5 w-5" />
            )}
          </button>
          <button
            onClick={stopPlayback}
            className="p-2 text-gray-500 hover:bg-gray-100 rounded-full"
            title="Stop playback"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}