import { toast } from 'react-hot-toast';

const DEVICE_LIMIT_KEY = 'poosting_device_registrations';

interface DeviceRegistration {
  email: string;
  timestamp: number;
}

export function getDeviceRegistrations(): DeviceRegistration[] {
  try {
    const registrations = localStorage.getItem(DEVICE_LIMIT_KEY);
    return registrations ? JSON.parse(registrations) : [];
  } catch (error) {
    console.error('Error getting device registrations:', error);
    return [];
  }
}

export function addDeviceRegistration(email: string): boolean {
  try {
    const registrations = getDeviceRegistrations();
    
    // Check if this email is already registered
    if (registrations.some(reg => reg.email === email)) {
      return true;
    }
    
    // Check if device limit is reached
    if (registrations.length >= 3) {
      toast.error('Device limit reached. Maximum 3 accounts per device.');
      return false;
    }
    
    // Add new registration
    registrations.push({
      email,
      timestamp: Date.now()
    });
    
    localStorage.setItem(DEVICE_LIMIT_KEY, JSON.stringify(registrations));
    return true;
  } catch (error) {
    console.error('Error adding device registration:', error);
    return false;
  }
}