import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Video, X, Camera, MapPin, Music, Clapperboard, Upload, BarChart } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { db, storage, analyticsEvents } from '../lib/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { LocationPicker } from './LocationPicker';
import { WebcamCapture } from './WebcamCapture';
import { SpotifySearch } from './SpotifySearch';
import { YoutubeSearch } from './YoutubeSearch';
import { CreatePoll } from './CreatePoll';
import { optimizeVideo } from '../utils/video';
import toast from 'react-hot-toast';

const MAX_POST_LENGTH = 120;
const POST_COOLDOWN = 60 * 60 * 1000; // 1 hour in milliseconds

interface PollOption {
  text: string;
  votes: string[];
}

interface Poll {
  question: string;
  options: PollOption[];
  totalVotes: number;
  endTime: Date;
}

export function CreatePost() {
  const [content, setContent] = useState('');
  const [media, setMedia] = useState<{ file: File; type: 'image' | 'video' } | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastPostTime, setLastPostTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [location, setLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [showWebcam, setShowWebcam] = useState<'photo' | 'video' | null>(null);
  const [showSpotifySearch, setShowSpotifySearch] = useState(false);
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [showPollCreator, setShowPollCreator] = useState(false);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [selectedTrack, setSelectedTrack] = useState<{
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  } | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<{
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  } | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const cameraInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    const lastPost = localStorage.getItem('lastPostTime');
    if (lastPost) {
      setLastPostTime(new Date(lastPost));
    }
  }, []);

  useEffect(() => {
    if (!lastPostTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastPostTime.getTime();
      const remaining = Math.max(0, POST_COOLDOWN - (now - lastPost));
      setCooldownRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastPostTime]);

  const resetFileInputs = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
    if (videoInputRef.current) videoInputRef.current.value = '';
    if (cameraInputRef.current) cameraInputRef.current.value = '';
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'video') => {
    const file = e.target.files?.[0];
    if (!file) {
      resetFileInputs();
      return;
    }

    if (type === 'video') {
      try {
        const loadingToast = toast.loading('Processing video...');
        const video = document.createElement('video');
        video.preload = 'metadata';
        
        await new Promise((resolve, reject) => {
          video.onloadedmetadata = resolve;
          video.onerror = reject;
          video.src = URL.createObjectURL(file);
        });

        if (video.duration > 30) {
          toast.dismiss(loadingToast);
          toast.error('Video must be 30 seconds or less');
          resetFileInputs();
          return;
        }

        const optimizedVideo = await optimizeVideo(file);
        setMedia({ file: optimizedVideo, type });
        setPreviewUrl(URL.createObjectURL(optimizedVideo));
        setContent(' ');
        toast.dismiss(loadingToast);
        toast.success('Video processed successfully');
      } catch (error) {
        console.error('Error processing video:', error);
        toast.error('Failed to process video');
        resetFileInputs();
      }
      return;
    }

    setMedia({ file, type });
    setPreviewUrl(URL.createObjectURL(file));
    setContent(' ');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!content.trim() && !media && !location && !selectedTrack && !selectedVideo && !poll) || !user || isSubmitting) return;

    if (cooldownRemaining > 0) {
      toast.error(`Please wait ${Math.ceil(cooldownRemaining / 60000)} minutes before posting again`);
      return;
    }

    if (content.length > MAX_POST_LENGTH) {
      toast.error(`Post must be ${MAX_POST_LENGTH} characters or less`);
      return;
    }

    // Check for URLs in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br))/gi;
    if (urlRegex.test(content)) {
      toast.error('URLs are not allowed in posts');
      return;
    }

    try {
      setIsSubmitting(true);
      let mediaUrl = '';
      let mediaType = null;
      
      if (media) {
        // Generate unique filename with timestamp and random string
        const timestamp = Date.now();
        const randomString = Math.random().toString(36).substring(7);
        const extension = media.type === 'image' ? 'jpg' : 'webm';
        const filename = `${timestamp}_${randomString}.${extension}`;
        
        // Store file in posts folder
        const storageRef = ref(storage, `posts/${filename}`);
        await uploadBytes(storageRef, media.file);
        mediaUrl = await getDownloadURL(storageRef);
        mediaType = media.type;
      }

      const timestamp = serverTimestamp();

      await addDoc(collection(db, 'posts'), {
        content: content.trim(),
        imageUrl: mediaType === 'image' ? mediaUrl : null,
        videoUrl: mediaType === 'video' ? mediaUrl : null,
        location: location,
        spotifyTrack: selectedTrack,
        youtubeVideo: selectedVideo,
        poll: poll ? {
          ...poll,
          options: poll.options.map(option => ({
            ...option,
            votes: [] // Reset votes for new poll
          }))
        } : null,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', ''),
        authorPhotoURL: user.photoURL,
        timestamp,
        views: 0,
        comments: [],
        likes: [],
        stars: [],
        trash: []
      });

      analyticsEvents.createPost();
      setContent('');
      setMedia(null);
      setPreviewUrl(null);
      setLocation(null);
      setSelectedTrack(null);
      setSelectedVideo(null);
      setPoll(null);
      setShowLocationPicker(false);
      
      // Update last post time
      const now = new Date();
      setLastPostTime(now);
      localStorage.setItem('lastPostTime', now.toISOString());
      
      toast.success('Post created successfully!');
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Error creating post');
    } finally {
      setIsSubmitting(false);
      resetFileInputs();
    }
  };

  return (
    <div className="bg-white">
      <form onSubmit={handleSubmit} className="border-b border-gray-200 p-4">
        <div className="flex flex-col items-center space-y-4">
          <Link to={`/profile/${user?.displayName?.replace('@', '')}`}>
            <img
              src={user?.photoURL || ''}
              alt={user?.displayName || 'User'}
              className="h-16 w-16 rounded-full"
            />
          </Link>
          <div className="w-full max-w-xl">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder={cooldownRemaining > 0 
                ? `Wait ${Math.ceil(cooldownRemaining / 60000)} minutes before posting again` 
                : "What's on your mind? 💭"}
              className={`w-full resize-none border-0 focus:ring-0 text-base placeholder-gray-500 bg-transparent transition-all duration-300 text-center ${
                content.length > MAX_POST_LENGTH ? 'text-red-500' : ''
              }`}
              rows={3}
              maxLength={MAX_POST_LENGTH}
              disabled={cooldownRemaining > 0}
            />

            {previewUrl && (
              <div className="relative mt-2">
                {media?.type === 'image' ? (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="rounded-2xl max-h-80 object-cover"
                  />
                ) : media?.type === 'video' ? (
                  <video
                    src={previewUrl}
                    className="rounded-2xl max-h-80 w-full"
                    controls
                  />
                ) : null}
                <button
                  type="button"
                  onClick={() => {
                    setMedia(null);
                    setPreviewUrl(null);
                    if (!content.trim()) {
                      setContent('');
                    }
                    resetFileInputs();
                  }}
                  className="absolute top-2 right-2 p-1 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            )}

            {selectedTrack && (
              <div className="mt-4 relative aspect-square">
                <img
                  src={selectedTrack.albumImage}
                  alt={selectedTrack.name}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                  <h3 className="text-3xl font-bold text-center mb-4">{selectedTrack.name}</h3>
                  <p className="text-xl text-center">{selectedTrack.artists.join(', ')}</p>
                  <button
                    type="button"
                    onClick={() => setSelectedTrack(null)}
                    className="absolute top-2 right-2 p-2 bg-black bg-opacity-50 rounded-full hover:bg-opacity-70"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}

            {selectedVideo && (
              <div className="mt-4 relative aspect-video">
                <img
                  src={selectedVideo.thumbnailUrl}
                  alt={selectedVideo.title}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                  <h3 className="text-xl font-bold text-center mb-2">{selectedVideo.title}</h3>
                  <p className="text-sm text-center">{selectedVideo.channelTitle}</p>
                  <button
                    type="button"
                    onClick={() => setSelectedVideo(null)}
                    className="absolute top-2 right-2 p-2 bg-black bg-opacity-50 rounded-full hover:bg-opacity-70"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}

            {poll && (
              <div className="mt-4 bg-gray-50 rounded-lg p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-semibold">{poll.question}</h3>
                  <button
                    type="button"
                    onClick={() => setPoll(null)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
                <div className="space-y-2">
                  {poll.options.map((option, index) => (
                    <div key={index} className="bg-white p-3 rounded border border-gray-200">
                      {option.text}
                    </div>
                  ))}
                </div>
                <p className="text-sm text-gray-500 mt-2">
                  Poll ends in {Math.ceil((poll.endTime.getTime() - Date.now()) / (1000 * 60 * 60))} hours
                </p>
              </div>
            )}

            {location && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">
                    📍 Location will be visible
                  </span>
                  <button
                    type="button"
                    onClick={() => setLocation(null)}
                    className="text-red-500 text-sm"
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4 flex flex-col space-y-4">
              <div className="flex justify-center space-x-2">
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="p-2 text-black hover:bg-blue-50 rounded-full"
                  title="Upload image"
                  disabled={cooldownRemaining > 0}
                >
                  <Image className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (videoInputRef.current) {
                      videoInputRef.current.click();
                    }
                  }}
                  className="p-2 text-black hover:bg-blue-50 rounded-full"
                  title="Upload video"
                  disabled={cooldownRemaining > 0}
                >
                  <Upload className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => isMobile ? cameraInputRef.current?.click() : setShowWebcam('photo')}
                  className="p-2 text-black hover:bg-blue-50 rounded-full"
                  title="Take photo"
                  disabled={cooldownRemaining > 0}
                >
                  <Camera className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => isMobile ? videoInputRef.current?.click() : setShowWebcam('video')}
                  className="p-2 text-black hover:bg-blue-50 rounded-full"
                  title="Record video (max 30 seconds)"
                  disabled={cooldownRemaining > 0}
                >
                  <Video className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowLocationPicker(true)}
                  className={`p-2 ${
                    location ? 'text-black' : 'text-black'
                  } hover:bg-blue-50 rounded-full`}
                  title="Share location"
                  disabled={cooldownRemaining > 0}
                >
                  <MapPin className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowSpotifySearch(true)}
                  className={`p-2 ${
                    selectedTrack ? 'text-green-500' : 'text-green-500'
                  } hover:bg-blue-50 rounded-full`}
                  title="Share music"
                  disabled={cooldownRemaining > 0}
                >
                  <Music className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowYoutubeSearch(true)}
                  className={`p-2 ${
                    selectedVideo ? 'text-red-500' : 'text-red-500'
                  } hover:bg-blue-50 rounded-full`}
                  title="Share video"
                  disabled={cooldownRemaining > 0}
                >
                  <Clapperboard className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowPollCreator(true)}
                  className={`p-2 ${
                    poll ? 'text-purple-500' : 'text-purple-500'
                  } hover:bg-blue-50 rounded-full`}
                  title="Create poll"
                  disabled={cooldownRemaining > 0}
                >
                  <BarChart className="h-5 w-5" />
                </button>
              </div>

              <div className="flex justify-center items-center space-x-4">
                <span className={`text-sm ${
                  content.length > MAX_POST_LENGTH ? 'text-red-500' : 'text-gray-500'
                }`}>
                  {content.length}/{MAX_POST_LENGTH}
                </span>
                <button
                  type="submit"
                  disabled={(!content.trim() && !media && !location && !selectedTrack && !selectedVideo && !poll) || isSubmitting || content.length > MAX_POST_LENGTH || cooldownRemaining > 0}
                  className="px-4 py-2 bg-black text-white rounded-full font-bold hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? 'Posting...' : cooldownRemaining > 0 ? `Wait ${Math.ceil(cooldownRemaining / 60000)}m` : 'Post'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Hidden file inputs */}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
          onClick={(e) => {
            // Reset value before opening
            (e.target as HTMLInputElement).value = '';
          }}
        />
        <input
          ref={videoInputRef}
          type="file"
          accept="video/*"
          onChange={(e) => handleFileSelect(e, 'video')}
          className="hidden"
          onClick={(e) => {
            // Reset value before opening
            (e.target as HTMLInputElement).value = '';
          }}
        />
        <input
          ref={cameraInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
          onClick={(e) => {
            // Reset value before opening
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </form>

      {showLocationPicker && (
        <LocationPicker
          onLocationSelect={(coords) => {
            setLocation(coords);
            setShowLocationPicker(false);
          }}
          onClose={() => setShowLocationPicker(false)}
        />
      )}

      {showWebcam && (
        <WebcamCapture
          mode={showWebcam}
          onCapture={handleFileSelect}
          onClose={() => setShowWebcam(null)}
          maxVideoDuration={30}
        />
      )}

      {showSpotifySearch && (
        <SpotifySearch
          onSelect={(track) => {
            setSelectedTrack(track);
            setContent(' ');
            setShowSpotifySearch(false);
          }}
          onClose={() => setShowSpotifySearch(false)}
        />
      )}

      {showYoutubeSearch && (
        <YoutubeSearch
          onSelect={(video) => {
            setSelectedVideo(video);
            setContent(' ');
            setShowYoutubeSearch(false);
          }}
          onClose={() => setShowYoutubeSearch(false)}
        />
      )}

      {showPollCreator && (
        <CreatePoll
          onPollCreated={(newPoll) => {
            setPoll(newPoll);
            setContent(' ');
            setShowPollCreator(false);
          }}
          onClose={() => setShowPollCreator(false)}
        />
      )}
    </div>
  );
}