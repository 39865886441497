import { Link, useLocation } from 'react-router-dom';
import { Eye, User, Search, Film, Music, MessageSquarePlus, Users, Youtube, MapPin, Trophy, Newspaper, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { ChatBadge } from './ChatBadge';
import { getMenuItems } from '../utils/navigation';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

export function MainMenu() {
  const location = useLocation();
  const { user } = useAuth();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  if (!user) return null;

  const menuItems = getMenuItems(user);

  const checkScrollButtons = () => {
    if (!scrollContainerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (!scrollContainerRef.current) return;

    const scrollAmount = 200;
    const container = scrollContainerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  return (
    <nav className="sticky top-[57px] z-40 bg-white border-b border-gray-200 shadow-sm">
      <div className="max-w-2xl mx-auto relative">
        {/* Left Arrow */}
        {showLeftArrow && (
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronLeft className="h-5 w-5 text-gray-600" />
          </button>
        )}

        {/* Menu Items */}
        <div 
          ref={scrollContainerRef}
          className="flex items-center overflow-x-auto scrollbar-hide py-2 px-4 scroll-smooth"
          onScroll={handleScroll}
        >
          {menuItems.map(({ icon: Icon, component: Component, path, title }) => {
            const isActive = location.pathname === path;
            return (
              <Link
                key={path}
                to={path}
                className={clsx(
                  'flex items-center justify-center px-4 py-2 whitespace-nowrap transition-colors',
                  isActive ? 'text-black font-medium' : 'text-gray-500 hover:text-black'
                )}
                title={title}
              >
                {Component ? (
                  <Component />
                ) : Icon && (
                  <Icon className="h-5 w-5" />
                )}
              </Link>
            );
          })}
        </div>

        {/* Right Arrow */}
        {showRightArrow && (
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronRight className="h-5 w-5 text-gray-600" />
          </button>
        )}
      </div>
    </nav>
  );
}