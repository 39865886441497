import { useState, useRef } from 'react';
import { LogOut, Film } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoryViewer } from '../components/StoryViewer';
import { CreateStory } from '../components/CreateStory';
import { useInfiniteStories } from '../hooks/useInfiniteStories';
import { StoryGrid } from '../components/StoryGrid';
import type { Story } from '../utils/stories/types';

export function Stories() {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState<number | null>(null);
  const { user } = useAuth();
  const videoPreviewRefs = useRef<{ [key: string]: HTMLVideoElement }>({});
  const previewTimersRef = useRef<{ [key: string]: number }>({});
  const { stories, setStories, loading } = useInfiniteStories();

  const handleStoryCreated = (newStory: Story) => {
    setStories(prev => [newStory, ...prev]);
  };

  const handleStoryDeleted = (storyId: string) => {
    setStories(prev => prev.filter(story => story.id !== storyId));
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Film className="h-6 w-6 text-blue-500" />
            <h1 className="text-xl font-bold">Poosting Stories</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <div className="max-w-2xl mx-auto bg-white min-h-screen">
        {selectedStoryIndex !== null ? (
          <StoryViewer
            stories={stories}
            initialIndex={selectedStoryIndex}
            onClose={() => setSelectedStoryIndex(null)}
            onDelete={handleStoryDeleted}
          />
        ) : (
          <div className="p-4">
            <CreateStory onStoryCreated={handleStoryCreated} />
            
            <StoryGrid
              stories={stories}
              loading={loading}
              videoPreviewRefs={videoPreviewRefs}
              previewTimersRef={previewTimersRef}
              onStorySelect={setSelectedStoryIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
}