import type { PostData } from './types';

export function mergeNewPosts(existingPosts: PostData[], newPosts: PostData[]): PostData[] {
  // Create a map of existing posts by ID for quick lookup
  const existingPostsMap = new Map(existingPosts.map(post => [post.id, post]));
  
  // Filter out any new posts that we already have
  const uniqueNewPosts = newPosts.filter(post => !existingPostsMap.has(post.id));
  
  // Combine existing and unique new posts
  const allPosts = [...existingPosts, ...uniqueNewPosts];
  
  // Sort by timestamp descending (newest first)
  return allPosts.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
}

export function ensureUniqueKey(post: PostData): PostData {
  if (!post._key) {
    return {
      ...post,
      _key: `post-${post.id}-${Date.now()}`
    };
  }
  return post;
}