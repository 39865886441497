import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

const STORIES_LIMIT = 100; // Fixed limit of 100 stories

export interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video' | 'spotify';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  views: string[];
  likes: string[];
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
}

export interface StoriesResponse {
  stories: Story[];
  hasMore: boolean;
}

export async function fetchStories(): Promise<StoriesResponse> {
  try {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const storiesRef = collection(db, 'stories');
    const q = query(
      storiesRef,
      where('timestamp', '>', twentyFourHoursAgo),
      orderBy('timestamp', 'desc'),
      limit(STORIES_LIMIT)
    );

    const snapshot = await getDocs(q);
    const stories = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate() || new Date()
    })) as Story[];

    // Group stories by user
    const storiesByUser = stories.reduce((acc: { [key: string]: Story[] }, story) => {
      if (!acc[story.authorId]) {
        acc[story.authorId] = [];
      }
      acc[story.authorId].push(story);
      return acc;
    }, {});

    // Sort each user's stories by timestamp
    Object.keys(storiesByUser).forEach(userId => {
      storiesByUser[userId].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    });

    // Flatten the stories array while maintaining user grouping
    const flattenedStories = Object.values(storiesByUser)
      .flat()
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

    return {
      stories: flattenedStories,
      hasMore: false // Always false since we're loading all at once
    };
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
}