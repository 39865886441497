import { useState, useEffect } from 'react';

export type FilterOption = 'all' | 'mine';

interface ContentFilterProps {
  onFilterChange: (option: FilterOption) => void;
  selectedOption: FilterOption;
}

export function ContentFilter({ onFilterChange, selectedOption }: ContentFilterProps) {
  return (
    <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-full">
      <button
        onClick={() => onFilterChange('all')}
        className={`px-3 py-1 text-sm rounded-full transition-colors ${
          selectedOption === 'all'
            ? 'bg-black text-white'
            : 'text-gray-600 hover:bg-gray-200'
        }`}
      >
        All
      </button>
      <button
        onClick={() => onFilterChange('mine')}
        className={`px-3 py-1 text-sm rounded-full transition-colors ${
          selectedOption === 'mine'
            ? 'bg-black text-white'
            : 'text-gray-600 hover:bg-gray-200'
        }`}
      >
        My
      </button>
    </div>
  );
}