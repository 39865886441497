import { User } from 'firebase/auth';
import { doc, updateDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';

export async function updateUserPhoto(file: File, user: User): Promise<string> {
  try {
    // Upload new photo
    const storageRef = ref(storage, `avatars/${user.uid}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);

    // Update user document with new photo URL only
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, { photoURL });

    // Update posts
    const postsQuery = query(collection(db, 'posts'), where('authorId', '==', user.uid));
    const postsSnapshot = await getDocs(postsQuery);
    
    if (!postsSnapshot.empty) {
      const batch = writeBatch(db);
      postsSnapshot.docs.forEach(doc => {
        batch.update(doc.ref, { authorPhotoURL: photoURL });
      });
      await batch.commit();
    }

    // Update comments
    const commentsQuery = query(collection(db, 'comments'), where('authorId', '==', user.uid));
    const commentsSnapshot = await getDocs(commentsQuery);
    
    if (!commentsSnapshot.empty) {
      const batch = writeBatch(db);
      commentsSnapshot.docs.forEach(doc => {
        batch.update(doc.ref, { authorPhotoURL: photoURL });
      });
      await batch.commit();
    }

    // Update notifications
    const notificationsQuery = query(collection(db, 'notifications'), where('senderId', '==', user.uid));
    const notificationsSnapshot = await getDocs(notificationsQuery);
    
    if (!notificationsSnapshot.empty) {
      const batch = writeBatch(db);
      notificationsSnapshot.docs.forEach(doc => {
        batch.update(doc.ref, { senderPhotoURL: photoURL });
      });
      await batch.commit();
    }

    return photoURL;
  } catch (error: any) {
    console.error('Photo update failed:', error);
    throw new Error('Failed to update photo. Please try again.');
  }
}