import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider, useAuth } from './components/AuthProvider';
import { PhotoProvider } from './contexts/PhotoContext';
import { LocalizationProvider } from './contexts/LocalizationContext';
import { RadioProvider } from './contexts/RadioContext';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Auth } from './pages/Auth';
import { Profile } from './pages/Profile';
import { Config } from './pages/Config';
import { Notifications } from './pages/Notifications';
import { Search } from './pages/Search';
import { WebSearch } from './pages/WebSearch';
import { Chat } from './pages/Chat';
import { Stories } from './pages/Stories';
import { News } from './pages/News';
import { Music } from './pages/Music';
import { Videos } from './pages/Videos';
import { Radio } from './pages/Radio';
import { Places } from './pages/Places';
import { Communities } from './pages/Communities';
import { CommunityDetails } from './pages/CommunityDetails';
import { TopicDetails } from './pages/TopicDetails';
import { CookiePolicy } from './pages/CookiePolicy';
import { TermsOfUse } from './pages/TermsOfUse';
import { WhatIsPoosting } from './pages/WhatIsPoosting';
import { AIChat } from './pages/AIChat';
import { NotFound } from './pages/NotFound';
import { Ranking } from './pages/Ranking';
import { Match } from './pages/Match';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return <>{children}</>;
}

function PublicRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return null;
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

export function App() {
  return (
    <Router>
      <AuthProvider>
        <PhotoProvider>
          <LocalizationProvider>
            <RadioProvider>
              <Routes>
                <Route path="/auth" element={
                  <PublicRoute>
                    <Auth />
                  </PublicRoute>
                } />

                <Route path="/" element={
                  <PrivateRoute>
                    <Layout>
                      <Home />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/profile/:username" element={
                  <PrivateRoute>
                    <Layout>
                      <Profile />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/config" element={
                  <PrivateRoute>
                    <Layout>
                      <Config />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/notifications" element={
                  <PrivateRoute>
                    <Layout>
                      <Notifications />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/search" element={
                  <PrivateRoute>
                    <Layout>
                      <Search />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/web" element={
                  <PrivateRoute>
                    <Layout>
                      <WebSearch />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/chat" element={
                  <PrivateRoute>
                    <Layout>
                      <Chat />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/stories" element={
                  <PrivateRoute>
                    <Layout>
                      <Stories />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/news" element={
                  <PrivateRoute>
                    <Layout>
                      <News />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/music" element={
                  <PrivateRoute>
                    <Layout>
                      <Music />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/videos" element={
                  <PrivateRoute>
                    <Layout>
                      <Videos />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/radio" element={
                  <PrivateRoute>
                    <Layout>
                      <Radio />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/places" element={
                  <PrivateRoute>
                    <Layout>
                      <Places />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/communities" element={
                  <PrivateRoute>
                    <Layout>
                      <Communities />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/communities/:id" element={
                  <PrivateRoute>
                    <Layout>
                      <CommunityDetails />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/communities/:communityId/topics/:topicId" element={
                  <PrivateRoute>
                    <Layout>
                      <TopicDetails />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/cookie-policy" element={
                  <PrivateRoute>
                    <Layout>
                      <CookiePolicy />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/terms-of-use" element={
                  <PrivateRoute>
                    <Layout>
                      <TermsOfUse />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/what-is-poosting" element={
                  <PrivateRoute>
                    <Layout>
                      <WhatIsPoosting />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/ai-chat" element={
                  <PrivateRoute>
                    <Layout>
                      <AIChat />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/ranking" element={
                  <PrivateRoute>
                    <Layout>
                      <Ranking />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="/match" element={
                  <PrivateRoute>
                    <Layout>
                      <Match />
                    </Layout>
                  </PrivateRoute>
                } />

                <Route path="*" element={<NotFound />} />
              </Routes>

              <Toaster 
                position="top-center"
                toastOptions={{
                  duration: 3000,
                  style: {
                    background: '#333',
                    color: '#fff',
                    fontSize: '14px',
                    padding: '16px',
                    borderRadius: '8px',
                    maxWidth: '500px',
                    textAlign: 'center'
                  },
                  success: {
                    iconTheme: {
                      primary: '#22c55e',
                      secondary: '#fff'
                    }
                  },
                  error: {
                    iconTheme: {
                      primary: '#ef4444',
                      secondary: '#fff'
                    }
                  }
                }}
              />
            </RadioProvider>
          </LocalizationProvider>
        </PhotoProvider>
      </AuthProvider>
    </Router>
  );
}