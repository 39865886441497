import { useState, useEffect } from 'react';
import { Bomb, Flame } from 'lucide-react';

interface PostLifetimeProps {
  timestamp: Date;
}

export function PostLifetime({ timestamp }: PostLifetimeProps) {
  const [progress, setProgress] = useState(100);
  const [isExpired, setIsExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState<{ hours: number; minutes: number; seconds: number }>({
    hours: 24,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTime = () => {
      const now = new Date().getTime();
      const postTime = timestamp.getTime();
      const expiryTime = postTime + (24 * 60 * 60 * 1000); // 24 hours in milliseconds
      const timeLeftMs = expiryTime - now;
      const totalDuration = 24 * 60 * 60 * 1000;
      
      if (timeLeftMs <= 0) {
        setProgress(0);
        setIsExpired(true);
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      // Calculate progress percentage
      const currentProgress = (timeLeftMs / totalDuration) * 100;
      setProgress(Math.max(0, Math.min(100, currentProgress)));

      // Calculate hours, minutes, seconds
      const hours = Math.floor(timeLeftMs / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeftMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeftMs % (1000 * 60)) / 1000);

      setTimeLeft({ hours, minutes, seconds });
    };

    calculateTime();
    const interval = setInterval(calculateTime, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <div className="mt-4 mb-1 flex items-center space-x-4">
      {/* Timeline container */}
      <div className="flex-1 relative">
        {/* Timeline bar */}
        <div className="h-1 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="h-full bg-gradient-to-r from-orange-300 via-orange-500 to-red-600 transition-all duration-1000"
            style={{ width: `${progress}%` }}
          />
        </div>

        {/* Bomb and flame icons */}
        <div 
          className="absolute top-1/2 -translate-y-1/2 transition-all duration-1000"
          style={{ left: `${progress}%`, transform: `translateX(-50%) translateY(-50%)` }}
        >
          <div className={`relative flex items-center ${
            isExpired 
              ? 'animate-bounce' 
              : 'animate-[wiggle_1s_ease-in-out_infinite]'
          }`}>
            {/* Single flame */}
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
              <Flame 
                className={`h-4 w-4 text-orange-500 ${
                  progress <= 50 ? 'animate-[flame_0.5s_ease-in-out_infinite]' : ''
                }`}
              />
            </div>

            {/* Bomb */}
            <Bomb 
              className={`h-5 w-5 text-black transform transition-transform ${
                progress <= 25 ? 'animate-[shake_0.5s_ease-in-out_infinite]' : ''
              }`}
            />

            {/* Warning indicator */}
            {progress <= 25 && (
              <div className="absolute -top-1 -right-1 h-2 w-2 bg-red-500 rounded-full animate-ping" />
            )}
          </div>
        </div>
      </div>

      {/* Countdown timer */}
      <div className="flex-shrink-0">
        <span className={`text-sm font-mono ${isExpired ? 'text-red-500' : 'text-gray-500'}`}>
          {isExpired ? (
            'Expired! 💥'
          ) : (
            <span className="tabular-nums">
              {String(timeLeft.hours).padStart(2, '0')}:
              {String(timeLeft.minutes).padStart(2, '0')}:
              {String(timeLeft.seconds).padStart(2, '0')}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}