import { useState, useEffect } from 'react';
import { LogOut, Radio as RadioIcon, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RadioSearch } from '../components/radio/RadioSearch';
import { RadioStation } from '../components/radio/RadioStation';
import { NowPlaying } from '../components/radio/NowPlaying';
import { fetchRadioStations } from '../utils/radio/api';
import toast from 'react-hot-toast';

interface RadioStation {
  name: string;
  url: string;
  favicon: string;
  country: string;
  language: string;
  tags: string;
  votes: number;
  clickCount: number;
}

export function Radio() {
  const [stations, setStations] = useState<RadioStation[]>([]);
  const [filteredStations, setFilteredStations] = useState<RadioStation[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentStation, setCurrentStation] = useState<RadioStation | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [audio] = useState(new Audio());

  useEffect(() => {
    loadStations();
  }, []);

  useEffect(() => {
    const filtered = stations.filter(station => 
      station.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      station.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
      station.language.toLowerCase().includes(searchQuery.toLowerCase()) ||
      station.tags.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredStations(filtered);
  }, [searchQuery, stations]);

  const loadStations = async () => {
    try {
      const data = await fetchRadioStations();
      
      // Sort stations by votes and clicks
      const sortedStations = data.sort((a, b) => 
        (b.votes + b.clickCount) - (a.votes + a.clickCount)
      );

      setStations(sortedStations);
      setFilteredStations(sortedStations);
    } catch (error) {
      console.error('Error loading radio stations:', error);
      toast.error('Failed to load radio stations');
    } finally {
      setLoading(false);
    }
  };

  const handlePlay = (station: RadioStation) => {
    if (currentStation?.url === station.url) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play().catch(() => {
          setIsPlaying(false);
          setCurrentStation(null);
        });
        setIsPlaying(true);
      }
    } else {
      if (currentStation) {
        audio.pause();
      }
      audio.src = station.url;
      audio.play().then(() => {
        setCurrentStation(station);
        setIsPlaying(true);
        toast.success('Playing ' + station.name);
      }).catch(() => {
        setIsPlaying(false);
        setCurrentStation(null);
      });
    }
  };

  useEffect(() => {
    audio.addEventListener('error', () => {
      setIsPlaying(false);
      setCurrentStation(null);
    });

    return () => {
      audio.pause();
      audio.src = '';
    };
  }, [audio]);

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <RadioIcon className="h-6 w-6 text-purple-500" />
            <h1 className="text-xl font-bold">Poosting Radio</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-purple-500" />
          </div>
        ) : (
          <div className="p-4">
            <RadioSearch 
              value={searchQuery}
              onChange={setSearchQuery}
            />

            {currentStation && (
              <NowPlaying
                station={currentStation}
                isPlaying={isPlaying}
                onPlayPause={() => handlePlay(currentStation)}
              />
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {filteredStations.map((station) => (
                <RadioStation
                  key={station.url}
                  station={station}
                  isPlaying={isPlaying}
                  isCurrentStation={currentStation?.url === station.url}
                  onClick={() => handlePlay(station)}
                />
              ))}
            </div>

            {filteredStations.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No radio stations found matching "{searchQuery}"
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}