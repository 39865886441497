import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Profile } from '../types/match';
import toast from 'react-hot-toast';

export function useMatchProfiles() {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    const fetchProfiles = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('uid', '!=', user.uid)
        );
        
        const snapshot = await getDocs(q);
        const fetchedProfiles = snapshot.docs
          .map(doc => ({
            uid: doc.id,
            ...doc.data()
          } as Profile))
          .filter(profile => {
            // Only include profiles with name and username
            if (!profile.name || !profile.username) return false;

            // Only include profiles with valid photo URLs
            if (!profile.photoURL) return false;

            // Exclude profiles with default avatar URLs
            if (profile.photoURL.includes('dicebear.com')) return false;

            // Check if photo URL is valid
            return isValidPhotoUrl(profile.photoURL);
          });

        // Shuffle profiles
        const shuffled = fetchedProfiles.sort(() => Math.random() - 0.5);
        setProfiles(shuffled);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        toast.error('Failed to load profiles');
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [user]);

  // Helper function to check if a photo URL is valid
  const isValidPhotoUrl = (url: string): boolean => {
    // Check if URL is from a trusted source (e.g., Firebase Storage)
    return url.includes('firebasestorage.googleapis.com');
  };

  const handleSwipe = async (liked: boolean) => {
    if (!user || !profiles[currentIndex]) return;

    try {
      const currentProfile = profiles[currentIndex];

      if (liked) {
        // Create match notification with heart emoji and personalized message
        await addDoc(collection(db, 'notifications'), {
          type: 'match',
          recipientId: currentProfile.uid,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          message: '❤️ I liked you!',
          timestamp: serverTimestamp(),
          read: false,
          matchData: {
            matchTimestamp: serverTimestamp(),
            matchType: 'heart',
            matchStatus: 'liked',
            initiatorId: user.uid,
            recipientId: currentProfile.uid
          }
        });

        // Show heart animation toast
        toast.success('Match sent! ❤️', {
          icon: '❤️',
          style: {
            background: '#fee2e2',
            color: '#ef4444',
            fontWeight: 'bold'
          },
          duration: 2000
        });
      }

      // Move to next profile
      setCurrentIndex(prev => prev + 1);
    } catch (error) {
      console.error('Error handling swipe:', error);
      toast.error('Failed to send match');
    }
  };

  return {
    profiles,
    loading,
    currentIndex,
    handleSwipe
  };
}