import { createContext, useContext, useState, useRef, useEffect } from 'react';
import { RadioPlaybackBar } from '../components/radio/RadioPlaybackBar';

interface RadioStation {
  name: string;
  url: string;
  favicon: string;
  country: string;
  language: string;
}

interface RadioContextType {
  currentStation: RadioStation | null;
  isPlaying: boolean;
  isLoading: boolean;
  playStation: (station: RadioStation) => void;
  stopPlayback: () => void;
  togglePlayback: () => void;
}

const RadioContext = createContext<RadioContextType | null>(null);

export function RadioProvider({ children }: { children: React.ReactNode }) {
  const [currentStation, setCurrentStation] = useState<RadioStation | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    audioRef.current = new Audio();
    audioRef.current.volume = 0.7;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const playStation = async (station: RadioStation) => {
    if (!station) return;

    try {
      setIsLoading(true);
      
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        
        audioRef.current.src = station.url;
        
        // Set up event listeners
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          await playPromise;
          setCurrentStation(station);
          setIsPlaying(true);
        }
      }
    } catch (error) {
      console.error('Error playing station:', error);
      setIsPlaying(false);
      setCurrentStation(null);
    } finally {
      setIsLoading(false);
    }
  };

  const stopPlayback = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    setIsPlaying(false);
    setCurrentStation(null);
  };

  const togglePlayback = () => {
    if (!audioRef.current || !currentStation) return;

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      setIsLoading(true);
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch(error => {
          console.error('Error playing audio:', error);
          setIsPlaying(false);
          setCurrentStation(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <RadioContext.Provider value={{ 
      currentStation, 
      isPlaying,
      isLoading,
      playStation, 
      stopPlayback,
      togglePlayback 
    }}>
      {children}
      <RadioPlaybackBar />
    </RadioContext.Provider>
  );
}

export function useRadio() {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error('useRadio must be used within a RadioProvider');
  }
  return context;
}