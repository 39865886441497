import { useState, useEffect, useCallback } from 'react';
import { CreatePost } from '../components/CreatePost';
import { LogOut, Settings, Bell } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { ContentFilter, FilterOption } from '../components/ContentFilter';
import { StoriesGallery } from '../components/StoriesGallery';
import { RssCarousel } from '../components/RssCarousel';
import { SpotifyTopTracks } from '../components/spotify/SpotifyTopTracks';
import { YouTubeTrendingCarousel } from '../components/youtube/YouTubeTrendingCarousel';
import { PostList } from '../components/PostList';
import { Link } from 'react-router-dom';
import { NotificationBadge } from '../components/NotificationBadge';
import { subscribeToFeed, fetchMorePosts } from '../utils/posts';
import { mergeNewPosts } from '../utils/posts/helpers';
import type { PostData } from '../utils/posts/types';
import toast from 'react-hot-toast';

export function Home() {
  const [posts, setPosts] = useState<PostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filterOption, setFilterOption] = useState<FilterOption>('all');
  const [lastDoc, setLastDoc] = useState<any>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setPosts([]);
    setLastDoc(null);
    setHasMore(true);

    const unsubscribe = subscribeToFeed(
      user.uid,
      filterOption,
      (updatedPosts) => {
        setPosts(updatedPosts);
        setLastDoc(updatedPosts[updatedPosts.length - 1] || null);
        setHasMore(updatedPosts.length === 10);
        setLoading(false);
      },
      (error) => {
        console.error('Feed subscription error:', error);
        toast.error('Failed to load posts');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, filterOption]);

  const handleLoadMore = useCallback(async () => {
    if (!user || !lastDoc || loadingMore || !hasMore) return;

    try {
      setLoadingMore(true);
      const result = await fetchMorePosts(user.uid, filterOption, lastDoc);
      
      setPosts(prevPosts => mergeNewPosts(prevPosts, result.posts));
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading more posts:', error);
      toast.error('Failed to load more posts');
    } finally {
      setLoadingMore(false);
    }
  }, [user, lastDoc, loadingMore, hasMore, filterOption]);

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold cursor-pointer hover:opacity-80 transition-opacity">
            Poosting
          </h1>
          <div className="flex items-center space-x-4">
            <ContentFilter 
              onFilterChange={setFilterOption}
              selectedOption={filterOption}
            />
            <Link
              to="/config"
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Settings"
            >
              <Settings className="h-5 w-5" />
            </Link>
            <Link
              to="/notifications"
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Notifications"
            >
              <NotificationBadge />
            </Link>
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Sign out"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <StoriesGallery />
        <CreatePost />
        <RssCarousel />
        <SpotifyTopTracks />
        <YouTubeTrendingCarousel />
        
        <PostList
          posts={posts}
          loading={loading}
          loadingMore={loadingMore}
          hasMore={hasMore}
          onLoadMore={handleLoadMore}
        />
      </main>
    </div>
  );
}