import { Loader2, Check, X } from 'lucide-react';

interface AuthInputProps {
  id: string;
  type: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  isChecking?: boolean;
  isValid?: boolean | null;
  maxLength?: number;
  prefix?: string;
  isTextarea?: boolean;
}

export function AuthInput({
  id,
  type,
  label,
  value,
  onChange,
  placeholder,
  required = true,
  isChecking = false,
  isValid = null,
  maxLength,
  prefix,
  isTextarea = false,
}: AuthInputProps) {
  const inputClasses = `appearance-none rounded-md relative block w-full px-3 py-2 ${
    prefix ? 'pl-7' : ''
  } border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm`;

  const renderValidationIcon = () => {
    if (isChecking) {
      return <Loader2 className="h-4 w-4 animate-spin text-gray-400" />;
    }
    if (isValid === true) {
      return <Check className="h-4 w-4 text-green-500" />;
    }
    if (isValid === false) {
      return <X className="h-4 w-4 text-red-500" />;
    }
    return null;
  };

  return (
    <div className="mb-4">
      <label htmlFor={id} className="sr-only">
        {label}
      </label>
      <div className="flex items-center space-x-2">
        <div className="relative flex-1">
          {prefix && (
            <span className="absolute left-3 top-2 text-gray-500">{prefix}</span>
          )}
          {isTextarea ? (
            <textarea
              id={id}
              name={id}
              required={required}
              maxLength={maxLength}
              className={inputClasses}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              rows={3}
            />
          ) : (
            <input
              id={id}
              name={id}
              type={type}
              required={required}
              maxLength={maxLength}
              className={inputClasses}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        </div>
        {(isChecking !== undefined || isValid !== null) && (
          <div className="flex items-center">
            {renderValidationIcon()}
          </div>
        )}
      </div>
      {maxLength && (
        <div className="text-xs text-gray-400 mt-1">
          {value.length}/{maxLength}
        </div>
      )}
    </div>
  );
}