import { useState, useRef, useEffect } from 'react';
import { Play, Pause, Clock } from 'lucide-react';
import { LocationMessage } from './LocationMessage';
import YouTube from 'react-youtube';
import { loadVideoPreview } from '../utils/video/preview';
import { VideoPlayer } from './VideoPlayer';
import { SpotifyTrack } from './SpotifyTrack';
import { YouTubeEmbed } from './YouTubeEmbed';
import type { PostContentProps } from '../types/post';

export function PostContent({
  content,
  imageUrl,
  videoUrl,
  audioUrl,
  location,
  spotifyTrack,
  youtubeVideo,
  authorName,
  authorPhotoURL
}: PostContentProps) {
  return (
    <>
      <p className="text-gray-900 whitespace-pre-wrap break-words mb-3">{content}</p>
      
      {imageUrl && (
        <div className="relative w-full aspect-square mb-3">
          <img
            src={imageUrl}
            alt="Post content"
            className="absolute inset-0 w-full h-full object-cover bg-black/5 rounded-lg"
            loading="lazy"
          />
        </div>
      )}

      {videoUrl && (
        <VideoPlayer url={videoUrl} />
      )}

      {audioUrl && (
        <div className="w-full mt-2 mb-3 bg-gray-50 rounded-lg p-3">
          <audio
            src={audioUrl}
            className="w-full"
            controls
            preload="metadata"
          />
        </div>
      )}

      {spotifyTrack && (
        <SpotifyTrack track={spotifyTrack} />
      )}

      {youtubeVideo && (
        <YouTubeEmbed video={youtubeVideo} />
      )}

      {location && (
        <div className="mb-3">
          <LocationMessage
            latitude={location.latitude}
            longitude={location.longitude}
            senderName={authorName}
            senderPhotoURL={authorPhotoURL || ''}
            isFeed={true}
          />
        </div>
      )}
    </>
  );
}