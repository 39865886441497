import { doc, deleteDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../lib/firebase';
import toast from 'react-hot-toast';

const ADMIN_EMAIL = 'afonsoalcantara@outlook.com';

export async function isAdmin(email: string | null): Promise<boolean> {
  return email?.toLowerCase() === ADMIN_EMAIL.toLowerCase();
}

export async function deleteAnyStory(storyId: string, currentUserEmail: string | null) {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete story document
    await deleteDoc(doc(db, 'stories', storyId));

    // Try to delete media file if it exists
    try {
      const storageRef = ref(storage, `stories/${storyId}`);
      await deleteObject(storageRef);
    } catch (error: any) {
      // Ignore storage/object-not-found errors
      if (error.code !== 'storage/object-not-found') {
        console.error('Error deleting story media:', error);
      }
    }

    toast.success('Story deleted by admin');
  } catch (error) {
    console.error('Error deleting story:', error);
    throw error;
  }
}