import { Link } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { VerificationBadge } from './VerificationBadge';
import { deleteAnyPost } from '../utils/admin';
import toast from 'react-hot-toast';

interface PostHeaderProps {
  id: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  allies?: string[];
  isAdmin: boolean;
}

export function PostHeader({
  id,
  authorId,
  authorName,
  authorUsername,
  authorPhotoURL,
  timestamp,
  allies = [],
  isAdmin
}: PostHeaderProps) {
  const handleAdminDelete = async () => {
    try {
      await deleteAnyPost(id, 'afonsoalcantara');
      toast.success('Post deleted by admin');
    } catch (error: any) {
      console.error('Error deleting post:', error);
      toast.error(error.message || 'Failed to delete post');
    }
  };

  return (
    <div className="flex items-start space-x-3 mb-2">
      <Link to={`/profile/${authorUsername}`}>
        <img
          src={authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`}
          alt={authorName}
          className="h-10 w-10 rounded-full"
        />
      </Link>
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Link 
              to={`/profile/${authorUsername}`}
              className="font-semibold hover:underline"
            >
              {authorName}
            </Link>
            {allies.length > 0 && (
              <VerificationBadge alliesCount={allies.length} />
            )}
          </div>
          {isAdmin && (
            <button
              onClick={handleAdminDelete}
              className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
              title="Admin: Delete Post"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          )}
        </div>
        <p className="text-sm text-gray-500">
          {formatDistanceToNow(timestamp, { addSuffix: true })}
        </p>
      </div>
    </div>
  );
}