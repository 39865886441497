import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader2, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';

interface User {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  createdAt: Date;
  allies?: string[];
}

export function RecentUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get recent users
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          orderBy('createdAt', 'desc'),
          limit(100)
        );

        const userSnapshot = await getDocs(q);
        const userData = userSnapshot.docs
          .map(doc => {
            const data = doc.data();
            // Only include users with valid data
            if (data.username && data.name) {
              return {
                uid: doc.id,
                name: data.name,
                username: data.username,
                photoURL: data.photoURL || null,
                allies: data.allies || [],
                createdAt: data.createdAt?.toDate() || new Date()
              } as User;
            }
            return null;
          })
          .filter((user): user is User => user !== null);

        // Filter out any duplicate users based on username
        const uniqueUsers = Array.from(
          new Map(userData.map(user => [user.username, user])).values()
        );

        setUsers(uniqueUsers);
      } catch (error) {
        console.error('Error fetching users data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="text-center py-8">
        <Users className="h-12 w-12 mx-auto text-gray-400 mb-3" />
        <p className="text-gray-500">No users found</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="px-4">
        <h2 className="text-lg font-semibold">Recent Users (Last 100)</h2>
        <div className="h-1 w-full bg-gradient-to-r from-blue-500 to-purple-500 rounded-full mt-2" />
      </div>
      
      <div className="divide-y divide-gray-200">
        {users.map((user) => (
          <Link
            key={`${user.uid}-${user.username}`}
            to={`/profile/${user.username}`}
            className="flex items-center space-x-3 p-4 hover:bg-gray-50 transition-colors"
          >
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <img
                src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
                alt={user.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`;
                }}
              />
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {user.name}
                </p>
                <VerificationBadge alliesCount={user.allies?.length || 0} />
              </div>
              <p className="text-sm text-gray-500 truncate">
                @{user.username}
              </p>
              <p className="text-xs text-gray-400">
                Joined {user.createdAt.toLocaleDateString()}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}