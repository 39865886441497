import { useState, useRef, useEffect } from 'react';

export function useAudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackId, setCurrentTrackId] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const playTrack = (trackId: string, previewUrl: string) => {
    // Stop current audio if playing
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }

    // Create and play new audio
    const audio = new Audio(previewUrl);
    audio.volume = 0.5;
    
    audio.onended = () => {
      setIsPlaying(false);
      setCurrentTrackId(null);
      audioRef.current = null;
    };

    audio.play().catch(error => {
      console.error('Error playing audio:', error);
      setIsPlaying(false);
      setCurrentTrackId(null);
    });

    audioRef.current = audio;
    setIsPlaying(true);
    setCurrentTrackId(trackId);
  };

  const pauseTrack = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  return {
    isPlaying,
    currentTrackId,
    playTrack,
    pauseTrack
  };
}