import { Heart, X } from 'lucide-react';

interface MatchActionsProps {
  onSwipe: (liked: boolean) => Promise<void>;
}

export function MatchActions({ onSwipe }: MatchActionsProps) {
  return (
    <div className="fixed bottom-20 left-0 right-0 flex justify-center space-x-4">
      <button
        onClick={() => onSwipe(false)}
        className="p-4 bg-red-500 rounded-full text-white shadow-lg hover:bg-red-600 transition-colors"
      >
        <X className="h-8 w-8" />
      </button>
      <button
        onClick={() => onSwipe(true)}
        className="p-4 bg-green-500 rounded-full text-white shadow-lg hover:bg-green-600 transition-colors"
      >
        <Heart className="h-8 w-8" />
      </button>
    </div>
  );
}