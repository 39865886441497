import { Volume2, Loader2 } from 'lucide-react';
import { useRadio } from '../../contexts/RadioContext';

interface RadioStationProps {
  station: {
    name: string;
    url: string;
    favicon: string;
    country: string;
    language: string;
  };
}

export function RadioStation({ station }: RadioStationProps) {
  const { currentStation, isPlaying, isLoading, playStation, stopPlayback } = useRadio();
  const isCurrentStation = currentStation?.url === station.url;

  const handleClick = () => {
    if (isCurrentStation && isPlaying) {
      stopPlayback();
    } else {
      playStation(station);
    }
  };

  return (
    <div
      className={`p-4 rounded-lg border ${
        isCurrentStation
          ? 'border-purple-500 bg-purple-50'
          : 'border-gray-200 hover:border-purple-500'
      } transition-colors cursor-pointer`}
      onClick={handleClick}
    >
      <div className="flex items-center space-x-3">
        <img
          src={station.favicon}
          alt={station.name}
          className="w-12 h-12 rounded-lg object-cover"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`;
          }}
        />
        <div className="flex-1 min-w-0">
          <h3 className="font-medium truncate">{station.name}</h3>
          <p className="text-sm text-gray-500 truncate">
            {station.country} • {station.language}
          </p>
        </div>
        {isCurrentStation && (isLoading ? (
          <Loader2 className="h-5 w-5 text-purple-500 animate-spin" />
        ) : isPlaying && (
          <Volume2 className="h-5 w-5 text-purple-500 animate-pulse" />
        ))}
      </div>
    </div>
  );
}