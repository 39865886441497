import { useEffect } from 'react';
import { useLocalization } from '../contexts/LocalizationContext';

export function RssCarousel() {
  const { country } = useLocalization();

  useEffect(() => {
    // Load RSS widget script
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/carousel.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="my-4 bg-white rounded-lg shadow">
      {country === 'BR' ? (
        <rssapp-carousel id="_Pctg7BGdikkgztMA"></rssapp-carousel>
      ) : (
        <rssapp-carousel id="78QZ5DDXtXHmq9hF"></rssapp-carousel>
      )}
    </div>
  );
}