import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { Loader2, LogOut } from 'lucide-react';
import { MainMenu } from '../components/MainMenu';
import { ProfileHeader } from '../components/ProfileHeader';
import { AlliesList } from '../components/AlliesList';
import { ProfileVotes } from '../components/ProfileVotes';
import { ProfileMenu } from '../components/ProfileMenu';
import toast from 'react-hot-toast';

interface UserProfile {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  likes?: string[];
  stars?: string[];
  trash?: string[];
  matches?: string[];
  allies?: string[];
}

export function Profile() {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { userPhoto } = usePhoto();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!username) {
        setError('Username not provided');
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);

        const usersRef = collection(db, 'users');
        const userQuery = query(
          usersRef,
          where('username', '==', username.toLowerCase())
        );

        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          setError('User not found');
          setLoading(false);
          return;
        }

        const userData = userSnapshot.docs[0].data() as UserProfile;
        userData.uid = userSnapshot.docs[0].id;

        // If this is the current user's profile, use the latest photo from context
        if (user && userData.uid === user.uid && userPhoto) {
          userData.photoURL = userPhoto;
        }

        setUserProfile(userData);
      } catch (error: any) {
        console.error('Error fetching profile:', error);
        setError('Failed to load profile');
        toast.error('Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [username, user, userPhoto]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
          <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className="text-xl font-bold">Poosting</h1>
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
          <MainMenu />
        </header>
        <div className="flex justify-center items-center p-8">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  if (error || !userProfile) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">{error || 'User not found'}</h2>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
        >
          Go Home
        </button>
      </div>
    );
  }

  const isOwnProfile = user?.uid === userProfile.uid;

  if (isBlocked) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Content blocked</h2>
        <p className="text-gray-600 mb-4">You have blocked this user's content.</p>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
        >
          Go Home
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <div className="flex items-center space-x-2">
            {!isOwnProfile && (
              <ProfileMenu
                profileId={userProfile.uid}
                currentUserId={user?.uid}
                onBlock={() => setIsBlocked(true)}
              />
            )}
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
              title="Sign out"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <ProfileHeader
          profile={userProfile}
          currentUserId={user?.uid}
          isOwnProfile={isOwnProfile}
        />

        {!isOwnProfile && (
          <ProfileVotes
            profileId={userProfile.uid}
            currentUserId={user?.uid}
            initialVotes={{
              likes: userProfile.likes || [],
              stars: userProfile.stars || [],
              trash: userProfile.trash || [],
              matches: userProfile.matches || []
            }}
            profileName={userProfile.name}
            profileUsername={userProfile.username}
          />
        )}

        <AlliesList 
          userId={userProfile.uid}
          allies={userProfile.allies || []}
        />
      </main>
    </div>
  );
}