import { useState, useEffect, useRef } from 'react';
import { Camera, Upload, Music, Loader2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { analyticsEvents } from '../lib/firebase';
import { validateStoryFile, validateVideoDuration } from '../utils/stories/validation';
import { uploadStory } from '../utils/stories/upload';
import { SpotifySearch } from './SpotifySearch';
import toast from 'react-hot-toast';

interface CreateStoryProps {
  onStoryCreated: (story: any) => void;
}

const STORY_COOLDOWN = 60 * 60 * 1000; // 1 hour in milliseconds

export function CreateStory({ onStoryCreated }: CreateStoryProps) {
  const [uploading, setUploading] = useState(false);
  const [showSpotifySearch, setShowSpotifySearch] = useState(false);
  const [lastStoryTime, setLastStoryTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const { user } = useAuth();
  const photoInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Load last story time from localStorage
    const lastPost = localStorage.getItem('lastStoryTime');
    if (lastPost) {
      setLastStoryTime(new Date(lastPost));
    }
  }, []);

  useEffect(() => {
    if (!lastStoryTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastStoryTime.getTime();
      const remaining = Math.max(0, STORY_COOLDOWN - (now - lastPost));
      setCooldownRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastStoryTime]);

  const resetFileInputs = () => {
    if (photoInputRef.current) photoInputRef.current.value = '';
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'video') => {
    const file = e.target.files?.[0];
    if (!file || !user) {
      resetFileInputs();
      return;
    }

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      resetFileInputs();
      return;
    }

    // Validate file
    const validation = validateStoryFile(file, type);
    if (!validation.valid) {
      toast.error(validation.error);
      resetFileInputs();
      return;
    }

    // Validate video length if applicable
    if (type === 'video') {
      const lengthValidation = await validateVideoDuration(file);
      if (!lengthValidation.valid) {
        toast.error(lengthValidation.error);
        resetFileInputs();
        return;
      }
    }

    try {
      setUploading(true);

      const story = await uploadStory({
        file,
        type,
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      // Update last story time only after successful upload
      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      // Reset file inputs after successful upload
      resetFileInputs();
    } catch (error: any) {
      toast.error(error.message || 'Failed to create story. Please try again.');
      resetFileInputs();
    } finally {
      setUploading(false);
    }
  };

  const handleSpotifyTrackSelect = async (track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  }) => {
    if (!user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      return;
    }

    try {
      setUploading(true);

      const story = await uploadStory({
        type: 'spotify',
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL,
        spotifyTrack: track
      });

      onStoryCreated(story);
      analyticsEvents.createStory();
      setShowSpotifySearch(false);

      // Update last story time only after successful upload
      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
    } catch (error: any) {
      toast.error(error.message || 'Failed to create music story');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <button
          onClick={() => photoInputRef.current?.click()}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-black text-white text-xs rounded-full hover:bg-gray-900 disabled:opacity-50"
        >
          <Camera className="h-4 w-4" />
          <span>Photo</span>
        </button>

        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-black text-white text-xs rounded-full hover:bg-gray-900 disabled:opacity-50"
        >
          <Upload className="h-4 w-4" />
          <span>Upload</span>
        </button>

        <button
          onClick={() => setShowSpotifySearch(true)}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-green-500 text-white text-xs rounded-full hover:bg-green-600 disabled:opacity-50"
        >
          <Music className="h-4 w-4" />
          <span>Music</span>
        </button>

        <input
          ref={photoInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
          onClick={(e) => {
            // Reset value before opening
            (e.target as HTMLInputElement).value = '';
          }}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,video/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file?.type.startsWith('image/')) {
              handleFileSelect(e, 'image');
            } else if (file?.type.startsWith('video/')) {
              handleFileSelect(e, 'video');
            }
          }}
          className="hidden"
          onClick={(e) => {
            // Reset value before opening
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </div>

      {cooldownRemaining > 0 && (
        <div className="text-sm text-gray-500 text-center">
          Please wait {Math.ceil(cooldownRemaining / 60000)} minutes before posting another story
        </div>
      )}

      {uploading && (
        <div className="flex items-center space-x-2">
          <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
          <span className="text-sm text-blue-500">Processing...</span>
        </div>
      )}

      {showSpotifySearch && (
        <SpotifySearch
          onSelect={handleSpotifyTrackSelect}
          onClose={() => setShowSpotifySearch(false)}
        />
      )}
    </div>
  );
}