import { BlockedContent } from './types';
import { getBlockedContentFromStorage, saveBlockedContent } from './storage';

export function getBlockedContent(userId: string): string[] {
  const blockedContent = getBlockedContentFromStorage();
  return blockedContent[userId] || [];
}

export function blockContent(userId: string, postId: string): void {
  try {
    const blockedContent = getBlockedContentFromStorage();
    
    if (!blockedContent[userId]) {
      blockedContent[userId] = [];
    }
    
    if (!blockedContent[userId].includes(postId)) {
      blockedContent[userId].push(postId);
      saveBlockedContent(blockedContent);
    }
  } catch (error) {
    console.error('Error blocking content:', error);
  }
}

export function unblockContent(userId: string, postId: string): void {
  try {
    const blockedContent = getBlockedContentFromStorage();
    if (!blockedContent[userId]) return;
    
    blockedContent[userId] = blockedContent[userId].filter(id => id !== postId);
    saveBlockedContent(blockedContent);
  } catch (error) {
    console.error('Error unblocking content:', error);
  }
}

export function isContentBlocked(userId: string, postId: string): boolean {
  const blockedPosts = getBlockedContent(userId);
  return blockedPosts.includes(postId);
}