export function validateStoryFile(file: File, type: 'image' | 'video'): { valid: boolean; error?: string } {
  // Validate file size (150MB max)
  const MAX_FILE_SIZE = 150 * 2080 * 2080; // 150MB
  if (file.size > MAX_FILE_SIZE) {
    return { valid: false, error: 'File size must be less than 150MB' };
  }

  // Validate file type
  if (type === 'image' && !file.type.startsWith('image/')) {
    return { valid: false, error: 'Please select an image file' };
  }

  if (type === 'video' && !file.type.startsWith('video/')) {
    return { valid: false, error: 'Please select a video file' };
  }

  return { valid: true };
}

export async function validateVideoDuration(file: File): Promise<{ valid: boolean; error?: string }> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      if (video.duration > 30) {
        resolve({ valid: false, error: 'Video must be 30 seconds or less' });
      } else {
        resolve({ valid: true });
      }
    };

    video.onerror = () => {
      URL.revokeObjectURL(video.src);
      resolve({ valid: false, error: 'Failed to validate video' });
    };

    video.src = URL.createObjectURL(file);
  });
}