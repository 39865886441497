import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { storage, db } from '../../lib/firebase';
import { optimizeVideo } from '../video';
import toast from 'react-hot-toast';

interface UploadStoryParams {
  file?: File;
  type: 'image' | 'video' | 'spotify';
  userId: string;
  userName: string;
  userPhotoURL: string | null;
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
}

export async function uploadStory({
  file,
  type,
  userId,
  userName,
  userPhotoURL,
  spotifyTrack
}: UploadStoryParams) {
  const loadingToast = toast.loading('Creating your story...');

  try {
    let mediaUrl = '';

    if (type !== 'spotify') {
      if (!file) throw new Error('File is required for image/video stories');

      let processedFile = file;
      if (type === 'video') {
        try {
          processedFile = await optimizeVideo(file);
        } catch (error) {
          console.error('Video optimization failed:', error);
          throw new Error('Failed to process video');
        }
      }

      // Generate unique filename
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(7);
      const filename = `${userId}_${timestamp}_${randomString}.${type === 'image' ? 'jpg' : 'webm'}`;

      // Upload to storage
      const storageRef = ref(storage, `stories/${filename}`);
      await uploadBytes(storageRef, processedFile);
      mediaUrl = await getDownloadURL(storageRef);
    }

    // Create story document
    const storyData = {
      mediaUrl,
      mediaType: type,
      authorId: userId,
      authorName: userName,
      authorUsername: userName.startsWith('@') ? userName.slice(1) : userName,
      authorPhotoURL: userPhotoURL,
      timestamp: serverTimestamp(),
      views: [],
      likes: [],
      ...(type === 'spotify' && { spotifyTrack })
    };

    const docRef = await addDoc(collection(db, 'stories'), storyData);

    toast.dismiss(loadingToast);
    toast.success('Story created successfully!');

    return {
      id: docRef.id,
      ...storyData,
      timestamp: new Date()
    };
  } catch (error) {
    toast.dismiss(loadingToast);
    console.error('Error uploading story:', error);
    throw error;
  }
}