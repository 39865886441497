import { ANALYTICS_CONFIG } from './config';

// Declare gtag as a global function
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// Initialize dataLayer
window.dataLayer = window.dataLayer || [];

// Initialize gtag function
function gtag(...args: any[]) {
  window.dataLayer.push(arguments);
}

export function initializeGoogleAnalytics() {
  // Add Google Analytics script
  const script = document.createElement('script');
  script.async = true;
  script.src = ANALYTICS_CONFIG.scriptSrc;
  document.head.appendChild(script);

  // Initialize gtag
  gtag('js', new Date());
  gtag('config', ANALYTICS_CONFIG.measurementId);
}

// Analytics event tracking functions
export const analyticsEvents = {
  pageView: (path: string) => {
    gtag('event', 'page_view', {
      page_path: path
    });
  },
  
  userLogin: () => {
    gtag('event', 'login');
  },

  userSignup: () => {
    gtag('event', 'sign_up');
  },

  postCreated: () => {
    gtag('event', 'create_post');
  },

  storyCreated: () => {
    gtag('event', 'create_story');
  }
};