import { useEffect, useRef, useCallback } from 'react';

export function useResizeObserver() {
  const ref = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const rafRef = useRef<number | null>(null);
  const skipNextRef = useRef(false);

  const cleanup = useCallback(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    }
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  }, []);

  useEffect(() => {
    const element = ref.current;
    if (!element) return cleanup;

    try {
      observerRef.current = new ResizeObserver((entries) => {
        // Skip if requested
        if (skipNextRef.current) {
          skipNextRef.current = false;
          return;
        }

        // Use rAF to batch notifications and prevent loop
        if (!rafRef.current) {
          rafRef.current = requestAnimationFrame(() => {
            skipNextRef.current = true;
            entries.forEach(() => {
              // Handle resize if needed
            });
            rafRef.current = null;
          });
        }
      });

      observerRef.current.observe(element);
    } catch (error) {
      console.error('ResizeObserver error:', error);
    }

    return cleanup;
  }, [cleanup]);

  return { ref };
}