import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoryViewer } from './StoryViewer';
import { Plus, Menu, LogOut, Loader2, WifiOff } from 'lucide-react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

export function StoriesGallery() {
  const [selectedStoryId, setSelectedStoryId] = useState<string | null>(null);
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const { user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);

  // Monitor online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      toast.success('Back online!');
    };
    
    const handleOffline = () => {
      setIsOffline(true);
      toast.error('You are offline. Some features may be limited.');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const storiesRef = collection(db, 'stories');
    const q = query(
      storiesRef,
      where('timestamp', '>', twentyFourHoursAgo),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        try {
          const storiesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date()
          })) as Story[];

          // Group stories by user and filter out video stories
          const storiesByUser = storiesData.reduce((acc: { [key: string]: Story[] }, story) => {
            // Skip video stories
            if (story.mediaType === 'video') return acc;
            
            if (!acc[story.authorId]) {
              acc[story.authorId] = [];
            }
            acc[story.authorId].push(story);
            return acc;
          }, {});

          // Sort each user's stories by timestamp
          Object.keys(storiesByUser).forEach(userId => {
            storiesByUser[userId].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
          });

          // Flatten the stories array while maintaining user grouping
          const flattenedStories = Object.values(storiesByUser)
            .map(userStories => userStories[0]) // Take the most recent story from each user
            .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

          setStories(flattenedStories);
          setError(null);
        } catch (err) {
          console.error('Error processing stories:', err);
          setError('Error loading stories');
        } finally {
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching stories:', err);
        setError('Error loading stories');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const handleStoryClick = (storyId: string, event: React.MouseEvent) => {
    event.preventDefault();
    if (isOffline) {
      toast.error('Cannot view stories while offline');
      return;
    }
    
    const storyIndex = stories.findIndex(story => story.id === storyId);
    if (storyIndex !== -1) {
      setSelectedStoryId(storyId);
    }
  };

  const handleStoryClose = () => {
    setSelectedStoryId(null);
  };

  const handleStoryDelete = (storyId: string) => {
    setStories(prev => prev.filter(story => story.id !== storyId));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center py-8 text-gray-500">
        <WifiOff className="h-12 w-12 mb-4" />
        <p>{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="border-b border-gray-200">
      <div className="max-w-2xl mx-auto px-4 py-4 relative">
        {isOffline && (
          <div className="mb-4 p-2 bg-yellow-50 text-yellow-800 rounded-lg text-sm flex items-center justify-center">
            <WifiOff className="h-4 w-4 mr-2" />
            You are currently offline
          </div>
        )}
        
        <div 
          ref={containerRef}
          className="flex space-x-4 overflow-x-auto scrollbar-hide relative"
        >
          <Link
            to="/stories"
            className="flex flex-col items-center space-y-1 flex-shrink-0"
          >
            <div className="w-16 h-16 rounded-full border-2 border-gray-200 flex items-center justify-center bg-gray-50">
              <Plus className="h-6 w-6 text-gray-400" />
            </div>
            <span className="text-xs text-gray-500">Add Story</span>
          </Link>

          {stories.map((story) => {
            const hasUnviewed = !story.views.includes(user?.uid || '');

            return (
              <div
                key={story.id}
                className="flex flex-col items-center space-y-1 flex-shrink-0"
              >
                <button
                  onClick={(e) => handleStoryClick(story.id, e)}
                  className={`p-1 rounded-full ${
                    hasUnviewed ? 'bg-gradient-to-tr from-yellow-400 to-pink-500' : 'bg-gray-200'
                  }`}
                  disabled={isOffline}
                >
                  <div className="w-14 h-14 rounded-full border-2 border-white overflow-hidden">
                    {story.mediaType === 'spotify' ? (
                      <img
                        src={story.spotifyTrack?.albumImage}
                        alt={story.authorName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={story.mediaUrl || story.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${story.authorId}`}
                        alt={story.authorName}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    )}
                  </div>
                </button>
                <Link
                  to={`/profile/${story.authorUsername}`}
                  className="text-xs text-gray-500 hover:text-gray-700 truncate w-16 text-center"
                >
                  {story.authorName.split(' ')[0]}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      {selectedStoryId !== null && (
        <StoryViewer
          stories={stories}
          initialIndex={stories.findIndex(story => story.id === selectedStoryId)}
          onClose={handleStoryClose}
          onDelete={handleStoryDelete}
        />
      )}
    </div>
  );
}