import { useEffect } from 'react';
import { Post } from './Post';
import { Loader2, ArrowUp } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import type { PostData } from '../utils/posts/types';
import { ensureUniqueKey } from '../utils/posts/helpers';

interface PostListProps {
  posts: PostData[];
  loading: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

export function PostList({ posts, loading, loadingMore, hasMore, onLoadMore }: PostListProps) {
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false
  });

  useEffect(() => {
    if (inView && hasMore && !loadingMore) {
      onLoadMore();
    }
  }, [inView, hasMore, loadingMore, onLoadMore]);

  const isPostExpired = (timestamp: Date) => {
    const now = new Date().getTime();
    const postTime = timestamp.getTime();
    const timeDiff = now - postTime;
    const hoursOld = timeDiff / (1000 * 60 * 60);
    return hoursOld > 24;
  };

  const hasExpiredPosts = posts.length > 0 && isPostExpired(posts[posts.length - 1].timestamp);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">No posts yet</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200">
      {posts.map((post) => (
        <Post key={post._key || post.id} {...ensureUniqueKey(post)} />
      ))}
      
      {/* Show loading indicator if loading more posts */}
      {loadingMore && (
        <div className="py-4 flex justify-center">
          <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
        </div>
      )}
      
      {/* Infinite scroll trigger - only render if there are more posts */}
      {hasMore && !loadingMore && !hasExpiredPosts && (
        <div ref={loadMoreRef} className="h-20" />
      )}
      
      {/* Show end message when we've reached expired posts */}
      {hasExpiredPosts && (
        <div className="py-8 text-center space-y-4">
          <p className="text-gray-500">
            You've reached the end, go back to the beginning
          </p>
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-colors"
          >
            <ArrowUp className="h-4 w-4" />
            <span>Back to top</span>
          </button>
        </div>
      )}
    </div>
  );
}