import { LogOut, Newspaper } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RssFeedList } from '../components/RssFeedList';
import { useLocalization } from '../contexts/LocalizationContext';
import { useEffect } from 'react';

export function News() {
  const { country } = useLocalization();

  useEffect(() => {
    // Load RSS widget script
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/feed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Newspaper className="h-6 w-6 text-gray-800" />
            <h1 className="text-xl font-bold">Poosting News</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <div className="p-4">
          {country === 'BR' ? (
            <RssFeedList />
          ) : (
            <div className="my-4 bg-white rounded-lg shadow">
              <rssapp-feed id="78QZ5DDXtXHmq9hF"></rssapp-feed>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}