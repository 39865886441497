// Utility functions for managing blocked content
const BLOCKED_CONTENT_KEY = 'poosting_blocked_content';

interface BlockedContent {
  [userId: string]: string[]; // userId -> array of blocked post IDs
}

export function getBlockedContent(userId: string): string[] {
  try {
    const blocked = localStorage.getItem(BLOCKED_CONTENT_KEY);
    if (!blocked) return [];
    
    const blockedContent: BlockedContent = JSON.parse(blocked);
    return blockedContent[userId] || [];
  } catch (error) {
    console.error('Error getting blocked content:', error);
    return [];
  }
}

export function blockContent(userId: string, postId: string): void {
  try {
    const blocked = localStorage.getItem(BLOCKED_CONTENT_KEY);
    const blockedContent: BlockedContent = blocked ? JSON.parse(blocked) : {};
    
    if (!blockedContent[userId]) {
      blockedContent[userId] = [];
    }
    
    if (!blockedContent[userId].includes(postId)) {
      blockedContent[userId].push(postId);
    }
    
    localStorage.setItem(BLOCKED_CONTENT_KEY, JSON.stringify(blockedContent));
  } catch (error) {
    console.error('Error blocking content:', error);
  }
}

export function unblockContent(userId: string, postId: string): void {
  try {
    const blocked = localStorage.getItem(BLOCKED_CONTENT_KEY);
    if (!blocked) return;
    
    const blockedContent: BlockedContent = JSON.parse(blocked);
    if (!blockedContent[userId]) return;
    
    blockedContent[userId] = blockedContent[userId].filter(id => id !== postId);
    localStorage.setItem(BLOCKED_CONTENT_KEY, JSON.stringify(blockedContent));
  } catch (error) {
    console.error('Error unblocking content:', error);
  }
}

export function isContentBlocked(userId: string, postId: string): boolean {
  const blockedPosts = getBlockedContent(userId);
  return blockedPosts.includes(postId);
}