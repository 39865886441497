import { BlockedContent, BlockedUsers } from './types';

const BLOCKED_CONTENT_KEY = 'poosting_blocked_content';
const BLOCKED_USERS_KEY = 'poosting_blocked_users';

export function getFromStorage<T>(key: string): T | null {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error reading from storage (${key}):`, error);
    return null;
  }
}

export function saveToStorage<T>(key: string, data: T): void {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(`Error saving to storage (${key}):`, error);
  }
}

export function getBlockedContentFromStorage(): BlockedContent {
  return getFromStorage<BlockedContent>(BLOCKED_CONTENT_KEY) || {};
}

export function getBlockedUsersFromStorage(): BlockedUsers {
  return getFromStorage<BlockedUsers>(BLOCKED_USERS_KEY) || {};
}

export function saveBlockedContent(data: BlockedContent): void {
  saveToStorage(BLOCKED_CONTENT_KEY, data);
}

export function saveBlockedUsers(data: BlockedUsers): void {
  saveToStorage(BLOCKED_USERS_KEY, data);
}