import { LogOut } from 'lucide-react';
import { auth } from '../lib/firebase';

export function WhatIsPoosting() {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-6">
        <article className="prose prose-sm max-w-none">
          <h1 className="text-2xl font-bold mb-4">Welcome to Poosting, the Social Network That's Unlike Any Other! 🚀</h1>
          <p className="mb-6">
            Poosting is here to revolutionize the way you interact on social media. Here, you control your connections, and every click sparks interaction. 🌐✨
          </p>

          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold flex items-center">🌍 Feed</h2>
              <p>
                Your passport to a global and organic feed! With the eye icon on the main menu, you can access a space where all users share their content. Post photos, record videos, share music, or even check in. Interact with likes, stars, use the trash icon if you don't like something, or take it a step further with a match. All of this comes with a feed, stories, a summary of the latest news, and much more.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">🎥 Stories</h2>
              <p>
                Want to capture your day? Tap the film icon! Post photos, record videos of up to 30 seconds, or upload from your gallery. The perfect space to showcase those special moments!
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">🔍 Search Users</h2>
              <p>
                Find friends and new allies! The magnifying glass icon connects you to incredible profiles in real-time. Simple, fast, and efficient.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">👫 Communities</h2>
              <p>
                With the two-person icon, join communities or create your own. Engage in discussions and feel the nostalgia of Orkut with a modern twist.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">🎶 Poosting Music</h2>
              <p>
                Discover music with the musical note icon. Listen to anything and check out the weekly Top 50. Feel the beat! 🎵
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">▶️ Videos</h2>
              <p>
                Tap the play icon to explore billions of videos. Enjoy content of all styles, whether short or long.
              </p>
            </section>
            
            <section>
              <h2 className="text-xl font-semibold flex items-center">🏆 Ranking</h2>
              <p>
                Compete in the Ranking with the trophy icon! Check out the Top 100 users with the highest engagement and climb the list with your best posts.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">👤 Profile</h2>
              <p>
                Your exclusive space, accessible via the person icon. Show off your style, level up with verification badges (gray, blue, yellow, red, and black), and receive votes on your profile with likes, stars, matches, or even a trash icon (nobody likes it, but it's there 🤷).
              </p>

              <div className="bg-gray-50 p-4 rounded-lg mt-4">
                <p className="font-medium mb-2">As you gain more Allies Followers, you unlock new verification badges on your profile, increasing your recognition:</p>
                <ul className="list-none space-y-2">
                  <li>1,000 Followers: Champion Status - Blue Badge</li>
                  <li>10,000 Followers: Legend Status - Red Badge</li>
                  <li>100,000 Followers: Elite Status - Black Badge</li>
                  <li>1,000,000 Followers: Star Status - Star Badge</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">🔔 Notifications</h2>
              <p>
                The bell icon keeps you updated on everything: likes, matches, comments, and more. Stay in the loop!
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">💬 Messages</h2>
              <p>
                Chat privately using the speech bubble icon. Send messages, photos, or share your location with real-time read confirmations. Convenient and secure.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">⚙️ Settings</h2>
              <p>
                Manage your account with the gear icon, ensuring top-notch security. Update your profile photo, check your account details, or contact 24/7 live support! You can also check out the latest users who joined Poosting.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">🚨 Security & Moderation</h2>
              <p>
                Inappropriate or harmful content? Not here! Our team ensures Poosting remains a safe and positive environment. Follow the rules and post responsibly.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold flex items-center">📬 Support 24/7</h2>
              <p>
                Need help? The settings menu features a button to connect directly with live chat support. We're always here for you!
              </p>
            </section>
          </div>
        </article>
      </main>
    </div>
  );
}