import { createContext, useContext, useState, useEffect } from 'react';
import { detectUserLocation } from '../utils/localization/detect';
import { translations } from '../utils/localization/translations';

interface LocalizationContextType {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: string, params?: Record<string, string>) => string;
  country: string;
}

const LocalizationContext = createContext<LocalizationContextType | null>(null);

export function LocalizationProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState(() => {
    // Tenta recuperar o idioma salvo
    const savedLang = localStorage.getItem('poosting_language');
    if (savedLang) return savedLang;
    
    // Tenta usar o idioma do navegador
    const browserLang = navigator.language;
    return browserLang.startsWith('pt') ? 'pt-BR' : 'en';
  });
  
  const [country, setCountry] = useState('US');

  useEffect(() => {
    const initializeLocalization = async () => {
      try {
        const location = await detectUserLocation();
        setCountry(location.country);
        
        // Só atualiza o idioma se não houver um já salvo
        if (!localStorage.getItem('poosting_language')) {
          setLanguage(location.language);
        }
      } catch (error) {
        console.error('Error initializing localization:', error);
      }
    };

    initializeLocalization();
  }, []);

  // Salva o idioma quando ele muda
  useEffect(() => {
    localStorage.setItem('poosting_language', language);
  }, [language]);

  const t = (key: string, params?: Record<string, string>): string => {
    try {
      const keys = key.split('.');
      let value = translations[language as keyof typeof translations];
      
      for (const k of keys) {
        if (value && typeof value === 'object') {
          value = value[k as keyof typeof value];
        } else {
          return key;
        }
      }

      let translatedText = value as string || key;

      // Substitui parâmetros se existirem
      if (params) {
        Object.entries(params).forEach(([param, replacement]) => {
          translatedText = translatedText.replace(`{${param}}`, replacement);
        });
      }

      return translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      return key;
    }
  };

  return (
    <LocalizationContext.Provider value={{ 
      language, 
      setLanguage,
      t, 
      country 
    }}>
      {children}
    </LocalizationContext.Provider>
  );
}

export function useLocalization() {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useLocalization must be used within a LocalizationProvider');
  }
  return context;
}