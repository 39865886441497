import { useState, useEffect, useRef } from 'react';
import { Camera, Loader2, UserPlus, UserMinus } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { updateUserPhoto } from '../utils/photo';
import { VerificationBadge } from './VerificationBadge';
import { ProfileProgress } from './ProfileProgress';
import toast from 'react-hot-toast';

interface ProfileHeaderProps {
  profile: {
    uid: string;
    name: string;
    username: string;
    photoURL: string;
    bio?: string;
    allies?: string[];
  };
  currentUserId: string | undefined;
  isOwnProfile: boolean;
}

export function ProfileHeader({ profile, currentUserId, isOwnProfile }: ProfileHeaderProps) {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isUpdatingFollow, setIsUpdatingFollow] = useState(false);
  const [alliesCount, setAlliesCount] = useState(profile.allies?.length || 0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { updateUserPhoto: updateContextPhoto } = usePhoto();

  useEffect(() => {
    if (currentUserId && profile.allies) {
      setIsFollowing(profile.allies.includes(currentUserId));
      setAlliesCount(profile.allies.length);
    }
  }, [currentUserId, profile.allies]);

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    const loadingToast = toast.loading('Updating your photo everywhere...');
    setUploadingPhoto(true);

    try {
      const photoURL = await updateUserPhoto(file, user);
      updateContextPhoto(photoURL);
      toast.success('Photo updated successfully!');
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || 'Failed to update photo');
    } finally {
      setUploadingPhoto(false);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFollowToggle = async () => {
    if (!currentUserId || isOwnProfile || isUpdatingFollow) return;

    try {
      setIsUpdatingFollow(true);
      const userRef = doc(db, 'users', profile.uid);

      // Update local state immediately for better UX
      const newAlliesCount = isFollowing ? alliesCount - 1 : alliesCount + 1;
      setAlliesCount(newAlliesCount);
      setIsFollowing(!isFollowing);

      if (isFollowing) {
        await updateDoc(userRef, {
          allies: arrayRemove(currentUserId)
        });
        toast.success('Unfollowed successfully');
      } else {
        await updateDoc(userRef, {
          allies: arrayUnion(currentUserId)
        });

        // Create notification
        await addDoc(collection(db, 'notifications'), {
          type: 'new_ally',
          recipientId: profile.uid,
          senderId: currentUserId,
          senderName: user?.displayName || '',
          senderUsername: user?.displayName?.replace('@', '') || '',
          senderPhotoURL: user?.photoURL,
          message: `${user?.displayName} started following you`,
          timestamp: serverTimestamp(),
          read: false
        });

        toast.success('Following successfully');
      }
    } catch (error) {
      // Revert local state on error
      const revertedAlliesCount = isFollowing ? alliesCount + 1 : alliesCount - 1;
      setAlliesCount(revertedAlliesCount);
      setIsFollowing(!isFollowing);
      
      console.error('Error updating follow status:', error);
      toast.error('Failed to update follow status');
    } finally {
      setIsUpdatingFollow(false);
    }
  };

  return (
    <div className="relative">
      <div className="h-40 bg-gradient-to-r from-gray-200 to-gray-300" />
      
      <div className="px-4 pb-6">
        <div className="flex flex-col items-center -mt-20">
          <div className="relative">
            <img
              src={profile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${profile.uid}`}
              alt={profile.name}
              className="h-40 w-40 rounded-full border-4 border-white object-cover bg-white shadow-lg"
            />
            {isOwnProfile && (
              <>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  className="hidden"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  disabled={uploadingPhoto}
                  className="absolute bottom-2 right-2 p-2 bg-black text-white rounded-full shadow-lg hover:bg-gray-800 transition-colors"
                  title="Change profile photo"
                >
                  {uploadingPhoto ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : (
                    <Camera className="h-5 w-5" />
                  )}
                </button>
              </>
            )}
          </div>

          <div className="text-center w-full mt-4">
            <div className="flex items-center justify-center space-x-2">
              <h2 className="text-2xl font-bold text-gray-900">{profile.name}</h2>
              <VerificationBadge alliesCount={alliesCount} />
            </div>
            <p className="text-gray-500">@{profile.username}</p>
            {profile.bio && (
              <p className="mt-2 text-gray-600 max-w-md mx-auto">{profile.bio}</p>
            )}

            {!isOwnProfile && currentUserId && (
              <button
                onClick={handleFollowToggle}
                disabled={isUpdatingFollow}
                className={`mt-4 px-6 py-2 rounded-full font-medium flex items-center justify-center space-x-2 ${
                  isFollowing 
                    ? 'bg-red-500 text-white hover:bg-red-600' 
                    : 'bg-black text-white hover:bg-gray-800'
                } disabled:opacity-50 w-48 mx-auto`}
              >
                {isUpdatingFollow ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : isFollowing ? (
                  <>
                    <UserMinus className="h-5 w-5" />
                    <span>Unfollow</span>
                  </>
                ) : (
                  <>
                    <UserPlus className="h-5 w-5" />
                    <span>Follow</span>
                  </>
                )}
              </button>
            )}

            <ProfileProgress alliesCount={alliesCount} />
          </div>
        </div>
      </div>
    </div>
  );
}