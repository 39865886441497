import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Topic, CreateTopicParams } from '../../types/community';

export async function createNewTopic({
  communityId,
  title,
  content,
  user
}: CreateTopicParams) {
  try {
    const topicData = {
      title: title.trim(),
      content: content.trim(),
      authorId: user.id,
      authorName: user.name,
      authorUsername: user.username,
      authorPhotoURL: user.photoURL,
      timestamp: serverTimestamp(),
      lastResponseAt: serverTimestamp(),
      responseCount: 0
    };

    const topicRef = await addDoc(
      collection(db, 'communities', communityId, 'topics'),
      topicData
    );

    return {
      id: topicRef.id,
      ...topicData,
      timestamp: new Date(),
      lastResponseAt: new Date()
    };
  } catch (error) {
    console.error('Error creating topic:', error);
    throw error;
  }
}

export function getUniqueTopics(topics: Topic[]): Topic[] {
  if (!Array.isArray(topics)) {
    console.warn('Invalid topics array provided to getUniqueTopics');
    return [];
  }

  // Filter out invalid topics first
  const validTopics = topics.filter(topic => 
    topic && 
    topic.timestamp && 
    topic.timestamp instanceof Date && 
    !isNaN(topic.timestamp.getTime())
  );

  // Create a Map to store unique topics by ID
  const uniqueTopicsMap = new Map<string, Topic>();
  
  try {
    // Sort topics by timestamp in descending order (newest first)
    const sortedTopics = [...validTopics].sort((a, b) => {
      const timeA = a.timestamp?.getTime() || 0;
      const timeB = b.timestamp?.getTime() || 0;
      return timeB - timeA;
    });

    // Add topics to map, overwriting duplicates with the most recent version
    sortedTopics.forEach(topic => {
      if (topic && topic.id && !uniqueTopicsMap.has(topic.id)) {
        uniqueTopicsMap.set(topic.id, topic);
      }
    });
  } catch (error) {
    console.error('Error processing topics:', error);
    return validTopics; // Return filtered valid topics if sorting fails
  }

  // Convert map values back to array
  return Array.from(uniqueTopicsMap.values());
}

export function validateTopic(title: string, content: string): { valid: boolean; error?: string } {
  if (!title?.trim()) {
    return { valid: false, error: 'Title is required' };
  }

  if (title.length > 100) {
    return { valid: false, error: 'Title must be 100 characters or less' };
  }

  if (!content?.trim()) {
    return { valid: false, error: 'Content is required' };
  }

  if (content.length > 10000) {
    return { valid: false, error: 'Content must be 10,000 characters or less' };
  }

  return { valid: true };
}