import { useState, useEffect } from 'react';
import { spotify } from '../../lib/spotify';
import { SpotifyTrackCarousel } from './SpotifyTrackCarousel';
import { Loader2, Music } from 'lucide-react';
import toast from 'react-hot-toast';
import type { SpotifyTrack } from './types';

export function SpotifyTopTracks() {
  const [tracks, setTracks] = useState<SpotifyTrack[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 3;

    const fetchTopTracks = async () => {
      try {
        setLoading(true);
        
        // Get today's top tracks playlist
        const response = await spotify.playlists.getPlaylist('37i9dQZEVXbMDoHDwVN2tF');
        
        // Get tracks with preview URLs
        const allTracks = response.tracks.items
          .map(item => ({
            id: item.track.id,
            name: item.track.name,
            artists: item.track.artists.map(artist => artist.name),
            albumName: item.track.album.name,
            albumImage: item.track.album.images[0]?.url,
            previewUrl: item.track.preview_url,
            spotifyUrl: item.track.external_urls.spotify
          }))
          .filter(track => track.previewUrl); // Only include tracks with preview URLs

        // Randomly select 30 tracks
        const randomTracks = allTracks
          .sort(() => Math.random() - 0.5)
          .slice(0, 30);

        setTracks(randomTracks);
      } catch (error) {
        console.error('Error fetching top tracks:', error);
        
        // Retry logic for token expiration
        if (retryCount < maxRetries) {
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
          await spotify.authenticate(); // Re-authenticate
          await fetchTopTracks();
        } else {
          toast.error('Unable to load music tracks');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTopTracks();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-6">
        <Loader2 className="h-8 w-8 animate-spin text-green-500" />
      </div>
    );
  }

  if (tracks.length === 0) {
    return (
      <div className="text-center py-6">
        <Music className="h-12 w-12 mx-auto text-gray-400 mb-2" />
        <p className="text-gray-500">No tracks available</p>
      </div>
    );
  }

  return <SpotifyTrackCarousel tracks={tracks} />;
}