import toast from 'react-hot-toast';

interface GeolocationOptions {
  enableHighAccuracy?: boolean;
  timeout?: number;
  maximumAge?: number;
}

export async function getCurrentPosition(options: GeolocationOptions = {}): Promise<GeolocationPosition> {
  const defaultOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
    ...options
  };

  return new Promise((resolve, reject) => {
    // Check if geolocation is supported
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
      return;
    }

    // Handle iOS-specific permissions
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      // Request permission explicitly for iOS
      navigator.permissions?.query({ name: 'geolocation' })
        .then(permissionStatus => {
          if (permissionStatus.state === 'denied') {
            reject(new Error('Location permission denied. Please enable location services in your device settings.'));
            return;
          }
          requestPosition();
        })
        .catch(() => {
          // If permissions API is not available, try getting position directly
          requestPosition();
        });
    } else {
      requestPosition();
    }

    function requestPosition() {
      const timeoutId = setTimeout(() => {
        reject(new Error('Location request timed out. Please try again.'));
      }, defaultOptions.timeout);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timeoutId);
          resolve(position);
        },
        (error) => {
          clearTimeout(timeoutId);
          let errorMessage = 'Could not get your location';
          
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = 'Location access denied. Please enable location services.';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = 'Location information unavailable. Please try again.';
              break;
            case error.TIMEOUT:
              errorMessage = 'Location request timed out. Please try again.';
              break;
          }
          
          reject(new Error(errorMessage));
        },
        defaultOptions
      );
    }
  });
}

export async function getUserCountry(): Promise<string> {
  try {
    const position = await getCurrentPosition({ timeout: 5000 });
    const response = await fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`
    );
    const data = await response.json();
    return data.countryCode || 'US';
  } catch (error) {
    console.error('Error getting user country:', error);
    return 'US'; // Default to US if geolocation fails
  }
}

export function watchPosition(
  onSuccess: (position: GeolocationPosition) => void,
  onError?: (error: GeolocationPositionError) => void,
  options: GeolocationOptions = {}
): number {
  const defaultOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
    ...options
  };

  if (!navigator.geolocation) {
    onError?.(new GeolocationPositionError());
    return 0;
  }

  return navigator.geolocation.watchPosition(
    onSuccess,
    (error) => {
      let errorMessage = 'Could not get your location';
      
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'Location access denied. Please enable location services.';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'Location information unavailable. Please try again.';
          break;
        case error.TIMEOUT:
          errorMessage = 'Location request timed out. Please try again.';
          break;
      }

      toast.error(errorMessage);
      onError?.(error);
    },
    defaultOptions
  );
}

export function clearWatch(watchId: number): void {
  if (navigator.geolocation) {
    navigator.geolocation.clearWatch(watchId);
  }
}