import { Eye } from 'lucide-react';
import { PostVotes } from './PostVotes';
import { PostMatch } from './PostMatch';
import { PostMenu } from './PostMenu';

interface PostFooterProps {
  id: string;
  authorId: string;
  likes: string[];
  stars: string[];
  trash: string[];
  matches: string[];
  views: number;
  onBlock: () => void;
}

export function PostFooter({ id, authorId, likes, stars, trash, matches, views, onBlock }: PostFooterProps) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <PostVotes
          postId={id}
          authorId={authorId}
          initialVotes={{ likes, stars, trash }}
        />
        <PostMatch
          postId={id}
          authorId={authorId}
          matches={matches}
        />
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex items-center text-gray-500">
          <Eye className="h-4 w-4 mr-1" />
          <span className="text-sm">{views.toLocaleString()}</span>
        </div>
        <PostMenu postId={id} authorId={authorId} onBlock={onBlock} />
      </div>
    </div>
  );
}