import { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';

interface MatchBannerProps {
  photoURL: string;
  name: string;
}

export function MatchBanner({ photoURL, name }: MatchBannerProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="absolute top-0 left-0 right-0 bg-gradient-to-b from-pink-500/80 to-transparent p-4 text-white backdrop-blur-sm animate-fade-in">
      <div className="flex items-center justify-center space-x-2">
        <Heart className="h-6 w-6 text-white animate-pulse" />
        <span className="text-lg font-semibold">It's a Match!</span>
        <Heart className="h-6 w-6 text-white animate-pulse" />
      </div>
    </div>
  );
}