import { Eye, Loader2, Music, Play } from 'lucide-react';
import { formatTimeAgo } from '../utils/date';
import type { Story } from '../utils/stories';

interface StoryGridProps {
  stories: Story[];
  loading: boolean;
  videoPreviewRefs: React.MutableRefObject<{ [key: string]: HTMLVideoElement }>;
  previewTimersRef: React.MutableRefObject<{ [key: string]: number }>;
  onStorySelect: (index: number) => void;
}

export function StoryGrid({
  stories,
  loading,
  videoPreviewRefs,
  previewTimersRef,
  onStorySelect
}: StoryGridProps) {
  const isAndroid = /Android/i.test(navigator.userAgent);

  const handleVideoLoad = (video: HTMLVideoElement) => {
    if (isAndroid) {
      video.currentTime = 0.1;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="mt-6 mb-16">
      <h2 className="text-lg font-semibold mb-4">Recent Stories</h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {stories.length > 0 ? (
          stories.map((story, index) => (
            <button
              key={story.id}
              onClick={() => onStorySelect(index)}
              className="relative aspect-[9/16] rounded-lg overflow-hidden focus:outline-none group"
            >
              {story.mediaType === 'spotify' ? (
                <div className="absolute inset-0">
                  <img
                    src={story.spotifyTrack?.albumImage}
                    alt={story.spotifyTrack?.name}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60 group-hover:from-black/30 group-hover:to-black/70 transition-colors">
                    <div className="absolute top-2 left-2">
                      <Music className="h-6 w-6 text-white" />
                    </div>
                  </div>
                </div>
              ) : story.mediaType === 'video' ? (
                <div className="relative w-full h-full">
                  <video
                    ref={el => {
                      if (el) {
                        videoPreviewRefs.current[story.id] = el;
                        el.addEventListener('loadedmetadata', () => handleVideoLoad(el));
                      }
                    }}
                    src={story.mediaUrl}
                    className="absolute inset-0 w-full h-full object-cover"
                    muted
                    playsInline
                    preload="metadata"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60 group-hover:from-black/30 group-hover:to-black/70 transition-colors">
                    <div className="absolute top-2 left-2">
                      <Play className="h-6 w-6 text-white" />
                    </div>
                  </div>
                </div>
              ) : (
                <img
                  src={story.mediaUrl}
                  alt={`Story by ${story.authorName}`}
                  className="absolute inset-0 w-full h-full object-cover"
                  loading="lazy"
                />
              )}
              <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60 group-hover:from-black/30 group-hover:to-black/70 transition-colors" />
              <div className="absolute bottom-2 left-2 right-2">
                <div className="flex items-center space-x-2">
                  <img
                    src={story.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${story.authorId}`}
                    alt={story.authorName}
                    className="w-6 h-6 rounded-full border border-white"
                  />
                  <div className="flex-1 min-w-0">
                    <p className="text-white text-sm font-medium truncate">
                      {story.authorName}
                    </p>
                    <p className="text-white/80 text-xs truncate">
                      {formatTimeAgo(story.timestamp)}
                    </p>
                  </div>
                </div>
                <div className="mt-1 flex items-center space-x-2">
                  <div className="flex items-center space-x-1">
                    <Eye className="h-3 w-3 text-white/80" />
                    <span className="text-xs text-white/80">
                      {story.views.length}
                    </span>
                  </div>
                  <span className="text-xs text-white/80">•</span>
                  <span className="text-xs text-white/80">
                    {story.mediaType === 'spotify' ? 'Music' : story.mediaType === 'video' ? 'Video' : 'Photo'}
                  </span>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="col-span-full text-center py-8 text-gray-500">
            <p className="text-lg">No stories yet</p>
            <p className="text-sm mt-2">Be the first to share a story!</p>
          </div>
        )}
      </div>
    </div>
  );
}