import { useState } from 'react';
import { X, Loader2, AlertTriangle } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { deleteUserAccount } from '../utils/account';
import toast from 'react-hot-toast';

interface DeleteAccountDialogProps {
  onClose: () => void;
}

export function DeleteAccountDialog({ onClose }: DeleteAccountDialogProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();

  const handleDeleteAccount = async () => {
    if (!user || isDeleting) return;

    const confirmed = window.confirm(
      'Are you absolutely sure you want to delete your account? This action cannot be undone and all your data will be permanently deleted.'
    );

    if (!confirmed) return;

    try {
      setIsDeleting(true);
      await deleteUserAccount(user);
    } catch (error: any) {
      console.error('Error deleting account:', error);
      toast.error(error.message || 'Failed to delete account');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-red-600">Delete Account</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-start space-x-3 bg-red-50 p-4 rounded-lg">
            <AlertTriangle className="h-6 w-6 text-red-600 flex-shrink-0 mt-0.5" />
            <div className="text-sm text-red-600">
              <p className="font-medium mb-1">Warning: This action is permanent</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>Your account will be permanently deleted</li>
                <li>All your posts and stories will be removed</li>
                <li>Your profile and data cannot be recovered</li>
                <li>You will lose all your connections and content</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteAccount}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50 flex items-center space-x-2"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Deleting...</span>
                </>
              ) : (
                <span>Delete My Account</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}