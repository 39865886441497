import { MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatTopicDate } from '../utils/date';
import { Topic } from '../types/community';
import { getUniqueTopics } from '../utils/community/topics';
import { useResizeObserver } from '../hooks/useResizeObserver';

interface TopicListProps {
  communityId: string;
  topics: Topic[];
}

export function TopicList({ communityId, topics }: TopicListProps) {
  const { ref } = useResizeObserver();
  const uniqueTopics = getUniqueTopics(topics);

  if (uniqueTopics.length === 0) {
    return (
      <div className="text-center py-8">
        <MessageSquare className="h-12 w-12 mx-auto text-gray-400 mb-3" />
        <p className="text-gray-500">No topics yet</p>
        <p className="text-sm text-gray-400 mt-1">Be the first to start a discussion!</p>
      </div>
    );
  }

  return (
    <div ref={ref} className="divide-y divide-gray-200">
      {uniqueTopics.map((topic) => {
        // Generate a safe key that doesn't depend on potentially undefined values
        const key = `${topic.id}-${topic.timestamp?.getTime() || Date.now()}`;
        
        return (
          <Link
            key={key}
            to={`/communities/${communityId}/topics/${topic.id}`}
            className="block p-4 hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-start space-x-3">
              <div className="flex-1 min-w-0">
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                  {topic.title}
                </h3>
                <p className="text-gray-600 line-clamp-2 mb-2">
                  {topic.content}
                </p>
                <div className="flex flex-wrap items-center gap-2 text-xs text-gray-500">
                  <div className="flex items-center space-x-1">
                    <MessageSquare className="h-3.5 w-3.5" />
                    <span>{topic.responseCount} responses</span>
                  </div>
                  <span>•</span>
                  <span>{formatTopicDate(topic.timestamp)}</span>
                  {topic.lastResponseAt && topic.responseCount > 0 && (
                    <>
                      <span>•</span>
                      <span className="text-gray-400">
                        Last response {formatTopicDate(topic.lastResponseAt)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}