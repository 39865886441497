import { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Video, Loader2 } from 'lucide-react';
import YouTube from 'react-youtube';
import axios from 'axios';
import { useLocalization } from '../../contexts/LocalizationContext';

interface Video {
  id: string;
  snippet: {
    title: string;
    channelTitle: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
  };
}

export function YouTubeTrendingCarousel() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const { country } = useLocalization();

  useEffect(() => {
    const fetchTrendingVideos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos`,
          {
            params: {
              part: 'snippet',
              chart: 'mostPopular',
              regionCode: country,
              maxResults: 50,
              key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
            }
          }
        );

        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching trending videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingVideos();
  }, [country]); // Refetch when country changes

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % videos.length);
    setSelectedVideo(null);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + videos.length) % videos.length);
    setSelectedVideo(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-red-500" />
      </div>
    );
  }

  if (videos.length === 0) {
    return null;
  }

  const currentVideo = videos[currentIndex];

  return (
    <div className="bg-black text-white p-6">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold flex items-center">
            <Video className="h-6 w-6 text-red-500 mr-2" />
            Trending Video Now {country && `(${country})`}
          </h2>
        </div>

        <div className="relative aspect-video rounded-lg overflow-hidden">
          {selectedVideo ? (
            <YouTube
              videoId={selectedVideo}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                  autoplay: 1,
                  modestbranding: 1,
                  rel: 0
                }
              }}
              className="w-full h-full"
              onEnd={() => setSelectedVideo(null)}
            />
          ) : (
            <>
              <img
                src={currentVideo.snippet.thumbnails.high.url}
                alt={currentVideo.snippet.title}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => setSelectedVideo(currentVideo.id)}
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                <h3 className="text-lg font-medium mb-2 line-clamp-2 max-w-lg">
                  {currentVideo.snippet.title}
                </h3>
                <p className="text-sm text-gray-300 mb-4">
                  {currentVideo.snippet.channelTitle}
                </p>
                <button
                  onClick={() => setSelectedVideo(currentVideo.id)}
                  className="px-4 py-2 bg-red-600 text-white text-sm rounded-full hover:bg-red-700 transition-colors"
                >
                  Play Video
                </button>
              </div>
            </>
          )}
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePrev}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <div className="text-center">
            <p className="text-sm text-gray-400">
              {currentIndex + 1} / {videos.length}
            </p>
          </div>
          <button
            onClick={handleNext}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
}