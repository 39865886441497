import { useState } from 'react';
import { X, Plus, Minus, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

interface PollOption {
  text: string;
  votes: string[];
}

interface Poll {
  question: string;
  options: PollOption[];
  totalVotes: number;
  endTime: Date;
}

interface CreatePollProps {
  onPollCreated: (poll: Poll) => void;
  onClose: () => void;
}

export function CreatePoll({ onPollCreated, onClose }: CreatePollProps) {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState<string[]>(['', '']);
  const [submitting, setSubmitting] = useState(false);

  const addOption = () => {
    if (options.length < 4) {
      setOptions([...options, '']);
    } else {
      toast.error('Maximum 4 options allowed');
    }
  };

  const removeOption = (index: number) => {
    if (options.length > 2) {
      setOptions(options.filter((_, i) => i !== index));
    } else {
      toast.error('Minimum 2 options required');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate question
    if (!question.trim()) {
      toast.error('Please enter a question');
      return;
    }

    // Validate options
    const validOptions = options.filter(opt => opt.trim());
    if (validOptions.length < 2) {
      toast.error('Please enter at least 2 options');
      return;
    }

    if (validOptions.length !== new Set(validOptions).size) {
      toast.error('Options must be unique');
      return;
    }

    // Create poll with 24-hour duration
    const poll: Poll = {
      question: question.trim(),
      options: validOptions.map(text => ({ text, votes: [] })),
      totalVotes: 0,
      endTime: new Date(Date.now() + 24 * 60 * 60 * 1000) // 24 hours from now
    };

    onPollCreated(poll);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Create Poll</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Question
            </label>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask a question..."
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={100}
            />
          </div>

          <div className="space-y-3">
            <label className="block text-sm font-medium text-gray-700">
              Options
            </label>
            {options.map((option, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                  placeholder={`Option ${index + 1}`}
                  className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxLength={50}
                />
                {options.length > 2 && (
                  <button
                    type="button"
                    onClick={() => removeOption(index)}
                    className="p-2 text-red-500 hover:bg-red-50 rounded-full"
                  >
                    <Minus className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            {options.length < 4 && (
              <button
                type="button"
                onClick={addOption}
                className="flex items-center space-x-2 text-blue-500 hover:text-blue-600"
              >
                <Plus className="h-5 w-5" />
                <span>Add Option</span>
              </button>
            )}
          </div>

          <p className="text-sm text-gray-500">
            Poll will be active for 24 hours
          </p>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
            >
              {submitting ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                'Create Poll'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}