import { useState, useEffect } from 'react';
import { LogOut, Search, Video } from 'lucide-react';
import { auth } from '../lib/firebase';
import YouTube from 'react-youtube';
import { MainMenu } from '../components/MainMenu';
import { VideoGrid } from '../components/youtube/VideoGrid';
import { fetchTrendingVideos, searchVideos } from '../utils/youtube';
import { useLocalization } from '../contexts/LocalizationContext';
import type { YouTubeVideo } from '../utils/youtube';

export function Videos() {
  const [videos, setVideos] = useState<YouTubeVideo[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const { country } = useLocalization();

  useEffect(() => {
    loadTrendingVideos();
  }, [country]); // Reload when country changes

  const loadTrendingVideos = async () => {
    try {
      setLoading(true);
      const trendingVideos = await fetchTrendingVideos(country);
      setVideos(trendingVideos);
    } catch (error) {
      console.error('Error loading videos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      const searchResults = await searchVideos(searchQuery, country);
      setVideos(searchResults);
    } catch (error) {
      console.error('Error searching videos:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Video className="h-6 w-6 text-red-500" />
            <h1 className="text-xl font-bold">Poosting Videos</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <form onSubmit={handleSearch}>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search videos..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </form>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {selectedVideo ? (
          <div className="p-4">
            <button
              onClick={() => setSelectedVideo(null)}
              className="mb-4 text-blue-500 hover:text-blue-600 flex items-center space-x-2"
            >
              <span>← Back to videos</span>
            </button>
            <div className="aspect-video mb-4">
              <YouTube
                videoId={selectedVideo}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                className="w-full h-full"
              />
            </div>
            {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet && (
              <div className="space-y-2">
                <h2 className="text-xl font-bold">
                  {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet.title}
                </h2>
                <p className="text-gray-600">
                  {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet.description}
                </p>
                <p className="text-sm text-gray-500">
                  {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet.channelTitle}
                </p>
              </div>
            )}
          </div>
        ) : (
          <VideoGrid
            videos={videos}
            loading={loading}
            onVideoSelect={setSelectedVideo}
          />
        )}
      </main>
    </div>
  );
}