import axios from 'axios';
import toast from 'react-hot-toast';

const YOUTUBE_API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';

export interface YouTubeVideo {
  id: string | {
    kind: string;
    videoId: string;
  };
  snippet: {
    title: string;
    description: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
    channelTitle: string;
    publishedAt: string;
  };
}

export async function fetchTrendingVideos(regionCode: string = 'US'): Promise<YouTubeVideo[]> {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos`,
      {
        params: {
          part: 'snippet',
          chart: 'mostPopular',
          maxResults: 50,
          regionCode,
          key: YOUTUBE_API_KEY
        }
      }
    );

    return response.data.items;
  } catch (error) {
    console.error('Error fetching trending videos:', error);
    throw new Error('Failed to load trending videos');
  }
}

export async function searchVideos(query: string, regionCode: string = 'US'): Promise<YouTubeVideo[]> {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/search`,
      {
        params: {
          part: 'snippet',
          q: query,
          maxResults: 50,
          type: 'video',
          regionCode,
          key: YOUTUBE_API_KEY
        }
      }
    );

    return response.data.items;
  } catch (error) {
    console.error('Error searching videos:', error);
    throw new Error('Failed to search videos');
  }
}