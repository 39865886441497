import { Star } from 'lucide-react';

export function EmptyMatchState() {
  return (
    <div className="flex flex-col items-center justify-center h-[60vh] text-center">
      <Star className="h-16 w-16 text-gray-400 mb-4" />
      <h2 className="text-xl font-semibold text-gray-900">No more profiles</h2>
      <p className="text-gray-500 mt-2">Check back later for more matches!</p>
    </div>
  );
}