import { useState, useRef } from 'react';
import { X, Loader2, Image as ImageIcon, Camera } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { ImageEditor } from './ImageEditor';
import toast from 'react-hot-toast';

interface CreateCommunityProps {
  onClose: () => void;
  onCreate: (title: string, question: string, imageFile: File | null) => Promise<void>;
}

export function CreateCommunity({ onClose, onCreate }: CreateCommunityProps) {
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowImageEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    const file = new File([blob], `community-${Date.now()}.jpg`, { type: 'image/jpeg' });
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
    setShowImageEditor(false);
    setSelectedImage(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || !question.trim() || !imageFile || submitting) {
      if (!imageFile) {
        toast.error('Please upload a community image');
      }
      return;
    }

    try {
      setSubmitting(true);
      await onCreate(title, question, imageFile);
      onClose();
    } catch (error) {
      console.error('Error creating community:', error);
      toast.error('Failed to create community');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b sticky top-0 bg-white">
          <h2 className="text-xl font-semibold">Create Community</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Community Image <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-center">
              <div className="relative">
                <div 
                  className={`w-32 h-32 rounded-lg border-2 ${
                    imagePreview ? 'border-solid border-gray-200' : 'border-dashed border-red-300'
                  } flex items-center justify-center overflow-hidden group cursor-pointer hover:border-blue-500 transition-colors`}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {imagePreview ? (
                    <>
                      <img
                        src={imagePreview}
                        alt="Community preview"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <Camera className="h-8 w-8 text-white" />
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <ImageIcon className="h-8 w-8 text-gray-400 mx-auto mb-2" />
                      <span className="text-sm text-gray-500">Upload Image</span>
                    </div>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  className="hidden"
                />
              </div>
            </div>
            {!imagePreview && (
              <p className="text-sm text-red-500 text-center mt-2">
                Community image is required
              </p>
            )}
          </div>

          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter a clear, descriptive title"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              maxLength={100}
            />
            <p className="text-xs text-gray-500 mt-1">
              {title.length}/100 characters
            </p>
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                Question or Topic <span className="text-red-500">*</span>
              </label>
              <button
                type="button"
                onClick={() => setShowPreview(!showPreview)}
                className="text-sm text-blue-500 hover:text-blue-600"
              >
                {showPreview ? 'Edit' : 'Preview'}
              </button>
            </div>
            
            {showPreview ? (
              <div className="prose max-w-none p-3 border rounded-lg bg-gray-50 min-h-[120px]">
                <ReactMarkdown>{question}</ReactMarkdown>
              </div>
            ) : (
              <textarea
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Describe your question or topic. You can use Markdown for formatting."
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                rows={4}
                required
                maxLength={120}
              />
            )}
            <p className="text-xs text-gray-500 mt-1">
              {question.length}/120 characters • Supports Markdown
            </p>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!title.trim() || !question.trim() || !imageFile || submitting}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
            >
              {submitting ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                'Create Community'
              )}
            </button>
          </div>
        </form>

        {showImageEditor && selectedImage && (
          <ImageEditor
            image={selectedImage}
            onSave={handleCroppedImage}
            onCancel={() => {
              setShowImageEditor(false);
              setSelectedImage(null);
            }}
            aspectRatio={1}
          />
        )}
      </div>
    </div>
  );
}