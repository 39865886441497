import { useState, useRef, useEffect } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { blockContent } from '../utils/blockContent';
import { blockUser } from '../utils/blockUser';
import toast from 'react-hot-toast';

interface StoryMenuProps {
  storyId: string;
  authorId: string;
  onBlock: () => void;
}

export function StoryMenu({ storyId, authorId, onBlock }: StoryMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleReport = () => {
    window.open(`https://poosting.com.br/report/index.html?storyId=${storyId}`, '_blank');
    setIsOpen(false);
  };

  const handleBlockContent = () => {
    if (!user) return;
    
    blockContent(user.uid, storyId);
    onBlock();
    toast.success('Content blocked successfully');
    setIsOpen(false);
  };

  const handleBlockUser = () => {
    if (!user || user.uid === authorId) return;
    
    blockUser(user.uid, authorId);
    toast.success('User blocked successfully');
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
        title="More options"
      >
        <MoreHorizontal className="h-5 w-5" />
      </button>

      {isOpen && (
        <div className="absolute bottom-full right-0 mb-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
          <button
            onClick={handleReport}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
          >
            Report
          </button>
          <button
            onClick={handleBlockContent}
            className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-50"
          >
            Block Abusive Content
          </button>
          {user?.uid !== authorId && (
            <button
              onClick={handleBlockUser}
              className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-50 border-t border-gray-100"
            >
              Block User
            </button>
          )}
        </div>
      )}
    </div>
  );
}