import { LogOut } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { MainMenu } from '../components/MainMenu';
import { MatchCard } from '../components/match/MatchCard';
import { MatchActions } from '../components/match/MatchActions';
import { EmptyMatchState } from '../components/match/EmptyMatchState';
import { useMatchProfiles } from '../hooks/useMatchProfiles';

export function Match() {
  const { profiles, loading, currentIndex, handleSwipe } = useMatchProfiles();
  const currentProfile = profiles[currentIndex];

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting Match</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <div className="pt-[30px]"> {/* Added 30px top padding */}
          {loading ? (
            <div className="flex justify-center items-center h-[60vh]">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
            </div>
          ) : currentProfile ? (
            <div className="relative h-[60vh] flex items-center justify-center">
              <MatchCard profile={currentProfile} onSwipe={handleSwipe} />
              <MatchActions onSwipe={handleSwipe} />
            </div>
          ) : (
            <EmptyMatchState />
          )}
        </div>
      </main>
    </div>
  );
}