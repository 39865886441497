import { Trash2, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useAuth } from '../components/AuthProvider';
import { deleteAnyCommunity } from '../utils/admin/communities';
import { useNavigate } from 'react-router-dom';

interface CommunityAdminControlsProps {
  communityId: string;
}

export function CommunityAdminControls({ communityId }: CommunityAdminControlsProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleAdminDelete = async () => {
    if (!user?.email) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this community? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      setIsDeleting(true);
      await deleteAnyCommunity(communityId, user.email);
      navigate('/communities');
    } catch (error: any) {
      console.error('Error deleting community:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (!user?.email?.toLowerCase().includes('afonsoalcantara@outlook.com')) {
    return null;
  }

  return (
    <button
      onClick={handleAdminDelete}
      disabled={isDeleting}
      className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
      title="Admin: Delete Community"
    >
      {isDeleting ? (
        <Loader2 className="h-5 w-5 animate-spin" />
      ) : (
        <Trash2 className="h-5 w-5" />
      )}
    </button>
  );
}