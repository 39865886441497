import { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause, ExternalLink, Music } from 'lucide-react';
import type { SpotifyTrack } from './types';
import { useAudioPlayer } from './useAudioPlayer';

interface SpotifyTrackCarouselProps {
  tracks: SpotifyTrack[];
}

export function SpotifyTrackCarousel({ tracks }: SpotifyTrackCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { 
    isPlaying, 
    currentTrackId, 
    playTrack, 
    pauseTrack 
  } = useAudioPlayer();

  const currentTrack = tracks[currentIndex];

  const handleNext = () => {
    if (isPlaying && currentTrackId === currentTrack.id) {
      pauseTrack();
    }
    setCurrentIndex((prev) => (prev + 1) % tracks.length);
  };

  const handlePrev = () => {
    if (isPlaying && currentTrackId === currentTrack.id) {
      pauseTrack();
    }
    setCurrentIndex((prev) => (prev - 1 + tracks.length) % tracks.length);
  };

  const handlePlayPause = () => {
    if (!currentTrack.previewUrl) {
      window.open(currentTrack.spotifyUrl, '_blank');
      return;
    }

    if (isPlaying && currentTrackId === currentTrack.id) {
      pauseTrack();
    } else {
      playTrack(currentTrack.id, currentTrack.previewUrl);
    }
  };

  // Auto-advance every 10 seconds if not playing
  useEffect(() => {
    if (!isPlaying || currentTrackId !== currentTrack.id) {
      const timer = setTimeout(handleNext, 10000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, isPlaying, currentTrackId, currentTrack.id]);

  return (
    <div className="bg-black text-white p-6 relative overflow-hidden" ref={containerRef}>
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold flex items-center">
            <Music className="h-6 w-6 text-green-500 mr-2" />
            Trending Music Now
          </h2>
          <a
            href={currentTrack.spotifyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-400 transition-colors"
          >
            <ExternalLink className="h-5 w-5" />
          </a>
        </div>

        <div className="relative aspect-square rounded-lg overflow-hidden group">
          <img
            src={currentTrack.albumImage}
            alt={currentTrack.albumName}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-6 text-center">
            <h3 className="text-2xl font-bold mb-2 line-clamp-2">{currentTrack.name}</h3>
            <p className="text-lg text-gray-300 mb-6 line-clamp-2">
              {currentTrack.artists.join(', ')}
            </p>
            <button
              onClick={handlePlayPause}
              className="p-4 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
            >
              {isPlaying && currentTrackId === currentTrack.id ? (
                <Pause className="h-8 w-8" />
              ) : (
                <Play className="h-8 w-8" />
              )}
            </button>
            {!currentTrack.previewUrl && (
              <p className="mt-4 text-sm text-gray-400">
                No preview available - Click to open in Spotify
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePrev}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <div className="text-center">
            <p className="text-sm text-gray-400">
              {currentIndex + 1} / {tracks.length}
            </p>
          </div>
          <button
            onClick={handleNext}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
}