import { Play, Pause, Loader2 } from 'lucide-react';

interface NowPlayingProps {
  station: {
    name: string;
    favicon: string;
    country: string;
    language: string;
  };
  isPlaying: boolean;
  isLoading: boolean;
  onPlayPause: () => void;
}

export function NowPlaying({ station, isPlaying, isLoading, onPlayPause }: NowPlayingProps) {
  return (
    <div className="sticky top-[57px] bg-white border-b border-gray-200 p-4 mb-4">
      <div className="flex items-center space-x-4">
        <img
          src={station.favicon}
          alt={station.name}
          className="w-16 h-16 rounded-lg object-cover"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`;
          }}
        />
        <div className="flex-1 min-w-0">
          <h2 className="text-lg font-semibold truncate">{station.name}</h2>
          <p className="text-sm text-gray-500">
            {station.country} • {station.language}
          </p>
        </div>
        <button
          onClick={onPlayPause}
          className="p-3 bg-purple-500 text-white rounded-full hover:bg-purple-600 disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : isPlaying ? (
            <Pause className="h-6 w-6" />
          ) : (
            <Play className="h-6 w-6" />
          )}
        </button>
      </div>
    </div>
  );
}