import { Calendar } from 'lucide-react';

export function RankingHeader() {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="p-4 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-1">24h Top 100</h2>
          <div className="flex items-center text-yellow-100">
            <Calendar className="h-4 w-4 mr-2" />
            <span>
              {formatDate(now)} - {formatDate(tomorrow)}
            </span>
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm text-yellow-100">like real-time</p>
        </div>
      </div>
    </div>
  );
}