import { BadgeCheck, Star } from 'lucide-react';

interface VerificationBadgeProps {
  alliesCount: number;
}

export function VerificationBadge({ alliesCount }: VerificationBadgeProps) {
  if (alliesCount < 1000) return null;

  let badgeColor = '';
  let title = '';
  let icon = BadgeCheck;

  if (alliesCount >= 1000000) {
    badgeColor = 'text-yellow-500';
    title = 'Star Status (1,000,000+ Allies)';
    icon = Star;
  } else if (alliesCount >= 100000) {
    badgeColor = 'text-black';
    title = 'Elite Status (100,000+ Allies)';
  } else if (alliesCount >= 10000) {
    badgeColor = 'text-red-500';
    title = 'Legend Status (10,000+ Allies)';
  } else if (alliesCount >= 1000) {
    badgeColor = 'text-blue-500';
    title = 'Champion Status (1,000+ Allies)';
  }

  const Icon = icon;
  return (
    <span title={title} className="inline-flex">
      <Icon className={`h-5 w-5 ${badgeColor}`} />
    </span>
  );
}