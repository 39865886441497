import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader2, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';

interface AlliesListProps {
  userId: string;
  allies: string[];
}

interface AllyUser {
  uid: string;
  username: string;
  photoURL: string;
  allies?: string[];
}

const INITIAL_ALLIES_COUNT = 12;
const ALLIES_PER_PAGE = 12;

export function AlliesList({ userId, allies }: AlliesListProps) {
  const [allyUsers, setAllyUsers] = useState<AllyUser[]>([]);
  const [displayedAllies, setDisplayedAllies] = useState<AllyUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (!userId) return;

    // Listen for changes to the user's allies
    const userRef = doc(db, 'users', userId);
    const unsubscribeUser = onSnapshot(userRef, async (userDoc) => {
      if (!userDoc.exists()) return;
      
      const currentAllies = userDoc.data().allies || [];
      if (currentAllies.length === 0) {
        setAllyUsers([]);
        setDisplayedAllies([]);
        setLoading(false);
        return;
      }

      try {
        const usersRef = collection(db, 'users');
        const alliesData: AllyUser[] = [];

        // Fetch allies in batches of 10 to avoid query limitations
        for (let i = 0; i < currentAllies.length; i += 10) {
          const batch = currentAllies.slice(i, i + 10);
          const alliesQuery = query(usersRef, where('uid', 'in', batch));
          const snapshot = await getDocs(alliesQuery);

          snapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.username) {
              alliesData.push({
                uid: doc.id,
                username: data.username,
                photoURL: data.photoURL || null,
                allies: data.allies || []
              });
            }
          });
        }

        // Sort allies by most recent first
        const sortedAllies = alliesData.sort((a, b) => {
          const indexA = currentAllies.indexOf(a.uid);
          const indexB = currentAllies.indexOf(b.uid);
          return indexB - indexA;
        });

        setAllyUsers(sortedAllies);
        setDisplayedAllies(sortedAllies.slice(0, INITIAL_ALLIES_COUNT));
        setHasMore(sortedAllies.length > INITIAL_ALLIES_COUNT);
      } catch (error) {
        console.error('Error fetching allies:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribeUser();
  }, [userId]);

  const loadMoreAllies = () => {
    setLoadingMore(true);
    const currentLength = displayedAllies.length;
    const nextAllies = allyUsers.slice(currentLength, currentLength + ALLIES_PER_PAGE);
    setDisplayedAllies(prev => [...prev, ...nextAllies]);
    setHasMore(currentLength + ALLIES_PER_PAGE < allyUsers.length);
    setLoadingMore(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!allies.length) {
    return (
      <div className="text-center py-8 px-4">
        <Users className="h-12 w-12 mx-auto text-gray-400 mb-3" />
        <p className="text-gray-500">No allies yet</p>
      </div>
    );
  }

  return (
    <div className="py-4 px-4">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <Users className="h-5 w-5 mr-2" />
        Allies Followers ({allies.length})
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {displayedAllies.map((ally) => (
          <Link
            key={`${ally.uid}-${ally.username}`}
            to={`/profile/${ally.username}`}
            className="flex flex-col items-center p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="w-16 h-16 rounded-full overflow-hidden">
              <img
                src={ally.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${ally.uid}`}
                alt={`@${ally.username}`}
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${ally.uid}`;
                }}
              />
            </div>
            <span className="mt-2 font-medium text-sm text-center truncate w-full">
              @{ally.username}
            </span>
            <VerificationBadge alliesCount={ally.allies?.length || 0} />
          </Link>
        ))}
      </div>

      {hasMore && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMoreAllies}
            disabled={loadingMore}
            className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 disabled:opacity-50 flex items-center space-x-2"
          >
            {loadingMore ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Loading...</span>
              </>
            ) : (
              <span>Show More</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}