import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { RankingItem } from '../types/ranking';

export function useRankingData() {
  const [rankings, setRankings] = useState<RankingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get start of current 24-hour period
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        // Query posts from last 24 hours
        const postsRef = collection(db, 'posts');
        const postsQuery = query(
          postsRef,
          where('timestamp', '>=', Timestamp.fromDate(twentyFourHoursAgo)),
          orderBy('timestamp', 'desc')
        );

        const postsSnapshot = await getDocs(postsQuery);
        
        // Aggregate likes by user
        const userLikes = new Map<string, {
          userId: string;
          name: string;
          username: string;
          photoURL: string | null;
          alliesCount: number;
          likesCount: number;
        }>();

        postsSnapshot.docs.forEach(doc => {
          const post = doc.data();
          const likes = post.likes?.length || 0;
          
          if (likes > 0) {
            const userData = userLikes.get(post.authorId) || {
              userId: post.authorId,
              name: post.authorName,
              username: post.authorUsername.toLowerCase().replace('@', ''),
              photoURL: post.authorPhotoURL,
              alliesCount: post.allies?.length || 0,
              likesCount: 0
            };
            
            userData.likesCount += likes;
            userLikes.set(post.authorId, userData);
          }
        });

        // Convert to array and sort by likes
        const rankingsList = Array.from(userLikes.values())
          .sort((a, b) => b.likesCount - a.likesCount)
          .slice(0, 100);

        setRankings(rankingsList);
      } catch (error) {
        console.error('Error fetching rankings:', error);
        setError('Failed to load rankings');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();

    // Refresh rankings every minute
    const interval = setInterval(fetchRankings, 60000);
    return () => clearInterval(interval);
  }, []);

  return { rankings, loading, error };
}