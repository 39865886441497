import { Trophy, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { RankingItem } from '../../types/ranking';
import { VerificationBadge } from '../VerificationBadge';

interface RankingListProps {
  rankings: RankingItem[];
}

export function RankingList({ rankings }: RankingListProps) {
  const getMedalColor = (position: number) => {
    switch (position) {
      case 1:
        return 'text-yellow-500';
      case 2:
        return 'text-gray-400';
      case 3:
        return 'text-amber-600';
      default:
        return 'text-gray-400';
    }
  };

  return (
    <div className="divide-y divide-gray-200">
      {rankings.map((ranking, index) => (
        <Link
          key={ranking.userId}
          to={`/profile/${ranking.username}`}
          className="flex items-center p-4 hover:bg-gray-50 transition-colors"
        >
          {/* Position/Trophy */}
          <div className="w-12 flex justify-center">
            {index + 1 <= 3 ? (
              <Trophy className={`h-6 w-6 ${getMedalColor(index + 1)}`} />
            ) : (
              <span className="text-lg font-bold text-gray-500">#{index + 1}</span>
            )}
          </div>
          
          {/* User Photo */}
          <div className="relative">
            <img
              src={ranking.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${ranking.userId}`}
              alt={ranking.name}
              className="w-14 h-14 rounded-full object-cover border-2 border-white shadow-md"
            />
            {index < 3 && (
              <div className={`absolute -top-1 -right-1 w-6 h-6 rounded-full flex items-center justify-center ${
                index === 0 ? 'bg-yellow-500' :
                index === 1 ? 'bg-gray-400' :
                'bg-amber-600'
              } text-white text-sm font-bold shadow-lg`}>
                {index + 1}
              </div>
            )}
          </div>
          
          {/* User Info */}
          <div className="flex-1 min-w-0 ml-4">
            <div className="flex items-center space-x-2">
              <h3 className="font-semibold text-gray-900 truncate">
                {ranking.name}
              </h3>
              <VerificationBadge alliesCount={ranking.alliesCount} />
            </div>
            <p className="text-sm text-gray-500 truncate">
              @{ranking.username}
            </p>
          </div>

          {/* Likes Count */}
          <div className="flex items-center space-x-2 ml-4">
            <Heart className="h-5 w-5 text-red-500 fill-current" />
            <span className="font-bold text-gray-900">
              {ranking.likesCount.toLocaleString()}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
}