import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { MoreHorizontal, Loader2, Heart } from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, serverTimestamp } from 'firebase/firestore';
import { usePhoto } from '../contexts/PhotoContext';
import { VerificationBadge } from './VerificationBadge';
import toast from 'react-hot-toast';

interface CommentProps {
  id: string;
  postId: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  onDelete: () => void;
  allies?: string[];
  likes?: string[];
}

export function Comment({
  id,
  postId,
  content,
  authorId,
  authorName,
  authorUsername,
  authorPhotoURL,
  timestamp,
  onDelete,
  allies = [],
  likes = []
}: CommentProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [likeCount, setLikeCount] = useState(likes.length);
  const [isLiked, setIsLiked] = useState(likes.includes(auth.currentUser?.uid || ''));
  const { userPhoto } = usePhoto();
  const currentUser = auth.currentUser;

  const handleLike = async () => {
    if (!currentUser) {
      toast.error('Please sign in to like comments');
      return;
    }

    if (isLiking) return;

    try {
      setIsLiking(true);
      const commentRef = doc(db, 'posts', postId, 'comments', id);

      if (isLiked) {
        await updateDoc(commentRef, {
          likes: arrayRemove(currentUser.uid)
        });
        setLikeCount(prev => prev - 1);
        setIsLiked(false);
      } else {
        await updateDoc(commentRef, {
          likes: arrayUnion(currentUser.uid)
        });
        setLikeCount(prev => prev + 1);
        setIsLiked(true);

        // Create notification if the like is not from the comment author
        if (currentUser.uid !== authorId) {
          await addDoc(collection(db, 'notifications'), {
            type: 'comment_like',
            postId,
            commentId: id,
            recipientId: authorId,
            senderId: currentUser.uid,
            senderName: currentUser.displayName || '',
            senderUsername: currentUser.displayName?.replace('@', '') || '',
            senderPhotoURL: currentUser.photoURL,
            timestamp: serverTimestamp(),
            read: false
          });
        }
      }
    } catch (error) {
      console.error('Error liking comment:', error);
      // Silently handle the error without showing a toast message
      // Revert optimistic updates
      setLikeCount(likes.length);
      setIsLiked(likes.includes(currentUser.uid));
    } finally {
      setIsLiking(false);
    }
  };

  const handleDelete = async () => {
    if (!currentUser || currentUser.uid !== authorId || deleting) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this comment?');
    if (!confirmDelete) return;

    try {
      setDeleting(true);

      // Delete the comment
      await deleteDoc(doc(db, 'posts', postId, 'comments', id));

      // Delete associated notifications
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('type', '==', 'comment'),
        where('postId', '==', postId),
        where('senderId', '==', authorId)
      );
      
      const notificationDocs = await getDocs(q);
      await Promise.all(
        notificationDocs.docs.map(doc => deleteDoc(doc.ref))
      );

      onDelete();
      setShowMenu(false);
      toast.success('Comment deleted');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    } finally {
      setDeleting(false);
    }
  };

  // Use userPhoto if the comment is from the current user
  const displayPhoto = currentUser?.uid === authorId && userPhoto ? userPhoto : (authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`);

  return (
    <div className="px-4 py-3 bg-white hover:bg-gray-50 transition-colors">
      <div className="flex items-start space-x-3">
        <Link to={`/profile/${authorUsername}`}>
          <img
            src={displayPhoto}
            alt={authorName}
            className="h-8 w-8 rounded-full"
          />
        </Link>
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Link 
                to={`/profile/${authorUsername}`}
                className="font-medium text-sm hover:underline"
              >
                {authorName}
              </Link>
              <VerificationBadge alliesCount={allies.length} />
              <span className="text-gray-500 text-sm">·</span>
              <span className="text-gray-500 text-sm">
                {formatDistanceToNow(timestamp, { addSuffix: true })}
              </span>
            </div>
            {currentUser?.uid === authorId && (
              <div className="relative">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={deleting}
                >
                  {deleting ? (
                    <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
                  ) : (
                    <MoreHorizontal className="h-4 w-4 text-gray-500" />
                  )}
                </button>
                {showMenu && (
                  <div className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <button
                      onClick={handleDelete}
                      disabled={deleting}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50 disabled:opacity-50"
                    >
                      Delete comment
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            {content}
          </p>
          <button
            onClick={handleLike}
            disabled={isLiking}
            className="mt-2 flex items-center space-x-1 text-gray-500 hover:text-red-500 transition-colors disabled:opacity-50"
          >
            <Heart 
              className={`h-4 w-4 ${isLiked ? 'fill-current text-red-500' : ''}`} 
            />
            {likeCount > 0 && (
              <span className="text-xs">{likeCount}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}